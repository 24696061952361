import {Client} from '@stomp/stompjs';

const wsrURI = process.env.REACT_APP_URL_WS.concat('/ws?token=')
const nodeEnv = process.env.NODE_ENV

export class WebsocketService {
    client;
    onConnectCb;
    onDisconnectCb;
    onErrorCb;
    #_isConnected = false;
    static #instance

    static getInstance(token) {
        if (!this.#instance) {
            this.#instance = new WebsocketService(token)
        }

        return this.#instance
    }

    constructor(token) {
        const url = wsrURI.concat(token)
        this.client = new Client({
            brokerURL: url,
            debug: nodeEnv === 'development' ? function (str) {
                console.log('WS debug: ', str);
            } : function () {
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        })

        this.client.onConnect = () => {
            this.#_isConnected = true;
            this.onConnectCb && this.onConnectCb();
        };

        this.client.onDisconnect = () => {
            this.#_isConnected = false;
            this.onDisconnectCb && this.onDisconnectCb();
        };

        this.client.onStompError = (frame) => {
            console.error('WS: Broker reported error: ' + frame.headers['message']);
            console.error('WS: Additional details: ' + frame.body);
            this.onErrorCb && this.onErrorCb();
        };
    }

    get isConnected() {
        return this.#_isConnected;
    }

    connect(onConnectCb, onDisconnectCb, onErrorCb) {
        this.onConnectCb = onConnectCb;
        this.onDisconnectCb = onDisconnectCb;
        this.onErrorCb = onErrorCb;

        this.client.activate();
    }

    disconnect() {
        this.client.deactivate();
    }

    subscribe(destination, cb) {
        return this.client.subscribe(destination, cb);
    }

    sendMessage(destination, body) {
        this.client.publish({destination, body});
    }
}
