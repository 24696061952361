export const tokenizationIntentErrors = {
    '-3': {
        whatsHappen: 'É necessário salvar o cartão antes de validá-lo.',
        whatToDo: () => `É necessário salvar o cartão antes de validá-lo.`
    },
    '-2': {
        whatsHappen: 'Sessão expirada.',
        whatToDo: () => `Para salvar o cartão, atualize a página.`
    },
    '-1': {
        whatsHappen: "Link para salvar cartão não encontrado.",
        whatToDo: () => "Link para salvar cartão não encontrado."
    },
    0: {
      whatsHappen: "Link para salvar cartão já efetivado.",
      whatToDo: store => `Este link para salvar o cartão já foi efetivado. Qualquer dúvida entre em contato com ${store}.`
    },
    1: {
        whatsHappen: 'Cartão salvo. Valide o cartão após o redirecionamento.',
        whatToDo: () => `Cartão salvo. Valide o cartão após o redirecionamento.`
    },
}
