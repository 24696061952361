function getDevice() {
    return {
        colorDepth: window.screen.colorDepth,
        deviceType3ds: "BROWSER",
        javaEnabled: window.navigator.javaEnabled() || false,
        language: window.navigator.language || window.navigator.userLanguage,
        screenHeight: window.innerHeight,
        screenWidth: window.innerWidth,
        timeZoneOffset: new Date().getTimezoneOffset() / 60
    }
}

export const deviceUtil = {
    getDevice
}
