export const intentErrors = {
    '-2': {
        whatsHappen: 'Sessão expirada para efetuar o pagamento.',
        whatToDo: () => `Para realizar o pagamento, atualize a página novamente.`
    },
    '-1': {
        whatsHappen: "Link de Pagamento não encontrado.",
        whatToDo: () => "Link de Pagamento não encontrado."
    },
    0: {
        whatsHappen: "Aguardando pagamento.",
        whatToDo: () => "Caso já tenha efetuado o pagamento, desconsidere o aviso.",
    },
    2: {
      whatsHappen: "Link de pagamento já efetivado.",
      whatToDo: () => "Link de pagamento já efetivado.",
    },
    4: {
        whatsHappen: "Link de Pagamento Expirado.",
        whatToDo: store => `Entre em contato com ${store} e solicite um novo link de pagamento.`
    },
    5: {
        whatsHappen: "Link de Pagamento aguardando confirmação.",
        whatToDo: store => `Este link de pagamento está aguardando confirmação.`
    },
    6: {
        whatsHappen: "Link de Pagamento pago.",
        whatToDo: store => `Este link de pagamento já foi pago. Qualquer dúvida entre em contato com ${store}.`
    },
    7: {
        whatsHappen: "Link de Pagamento negado.",
        whatToDo: store => `Este link de pagamento foi negado. Para um novo pagamento entre em contato com ${store}.`
    },
    8: {
        whatsHappen: "Link de Pagamento em análise para cancelamento.",
        whatToDo: store => `Este link de pagamento foi submetido à análise para cancelamento. Para um novo pagamento entre em contato com ${store}.`
    },
    9: {
        whatsHappen: "Link de Pagamento cancelado.",
        whatToDo: store => `Este link de pagamento foi cancelado. Para um novo pagamento entre em contato com ${store}.`
    },
    10: {
        whatsHappen: "Link de Pagamento em análise.",
        whatToDo: store => `Este link de pagamento ainda encontra-se em análise. Para mais informações entre em contato com ${store}.`
    },
    11: {
        whatsHappen: "Link de Pagamento não processado.",
        whatToDo: store => `Houve uma falha na comunicação para processar o pagamento. Solicite um novo pagamento com ${store}.`
    },
    12: {
        whatsHappen: "Link de Pagamento cancelado.",
        whatToDo: store => `Este link de pagamento foi cancelado. Para um novo pagamento entre em contato com ${store}.`
    },
}
