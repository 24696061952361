export const GTM = {
    PAYMENT_OPTION_CARD: {value: 'cartao'},
    PAYMENT_OPTION_BANK_SLIP: {value: 'boleto'},
    PAYMENT_OPTION_BANK_TRANSFER: {value: 'transferencia'},
    PAYMENT_OPTION_PIX: {value: 'pix'},
    PAYMENT_OPTION_CONFIRM_CARD: {value: 'confirmar-cartao'},
    PAYMENT_OPTION_CONFIRM_BANK_SLIP: {value: 'confirmar-boleto'},
    PAYMENT_OPTION_CONFIRM_BANK_TRANSFER: {value: 'confirmar-transferencia'},
    PAYMENT_OPTION_CONFIRM_PIX: {value: 'confirmar-pix'},
    GET_BANK_SLIP: {value: 'obter-boleto'},
}

function setDataLayer(gtm) {
    (window.dataLayer || []).push({
        'event': gtm.value,
    });
}

const paymentOptionCard = option => {
    setDataLayer(option ? GTM.PAYMENT_OPTION_CARD : GTM.PAYMENT_OPTION_CONFIRM_CARD);
}

const paymentOptionBankslip = option => {
    setDataLayer(option ? GTM.PAYMENT_OPTION_BANK_SLIP : GTM.PAYMENT_OPTION_CONFIRM_BANK_SLIP);
}

const paymentOptionTransfer = option => {
    setDataLayer(option ? GTM.PAYMENT_OPTION_BANK_TRANSFER : GTM.PAYMENT_OPTION_CONFIRM_BANK_TRANSFER);
}

const paymentOptionPix = option => {
    setDataLayer(option ? GTM.PAYMENT_OPTION_PIX : GTM.PAYMENT_OPTION_CONFIRM_PIX);
}

const getBankslip = () => {
    setDataLayer(GTM.GET_BANK_SLIP);
}

export const gtmService = {
    paymentOptionCard,
    paymentOptionBankslip,
    paymentOptionTransfer,
    paymentOptionPix,
    getBankslip
}
