import './cardIcon.scss'
import React, {Suspense} from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";

const AmexIcon = React.lazy(() => import('../icons/cards/amex'))
const DinersClubIcon = React.lazy(() => import('../icons/cards/dinersClub'))
const DiscoverIcon = React.lazy(() => import('../icons/cards/discover'))
const EloIcon = React.lazy(() => import('../icons/cards/elo'))
const HiperIcon = React.lazy(() => import('../icons/cards/hiper'))
const HiperCardIcon = React.lazy(() => import('../icons/cards/hipercard'))
const JCBIcon = React.lazy(() => import('../icons/cards/jcb'))
const MaestroIcon = React.lazy(() => import('../icons/cards/maestro'))
const MastercardIcon = React.lazy(() => import('../icons/cards/mastercard'))
const UnknownIcon = React.lazy(() => import('../icons/cards/unknown'))
const VisaIcon = React.lazy(() => import('../icons/cards/visa'))
const EloCaixa = React.lazy(() => import('../icons/cards/eloCaixa'))
const Banescard = React.lazy(() => import('../icons/cards/banescard'))
const Cabal = React.lazy(() => import('../icons/cards/cabal'))
const Credsystem = React.lazy(() => import('../icons/cards/credsystem'))
const Credz = React.lazy(() => import('../icons/cards/credz'))
const Sorocred = React.lazy(() => import('../icons/cards/sorocred'))
const Aura = React.lazy(() => import('../icons/cards/aura'))

const icons = {
    'unknown': {
        component: UnknownIcon,
        name: 'Bandeira não cadastrada'
    },
    'visa': {
        component: VisaIcon,
        name: 'Visa'
    },
    'mastercard': {
        component: MastercardIcon,
        name: 'Mastercard'
    },
    'discover': {
        component: DiscoverIcon,
        name: 'Discover'
    },
    'american-express': {
        component: AmexIcon,
        name: 'Amex'
    },
    'jcb': {
        component: JCBIcon,
        name: 'JCB'
    },
    'diners-club': {
        component: DinersClubIcon,
        name: 'Diners Club'
    },
    'maestro': {
        component: MaestroIcon,
        name: 'Maestro'
    },
    'elo': {
        component: EloIcon,
        name: 'Elo'
    },
    'hipercard': {
        component: HiperCardIcon,
        name: 'Hipercard'
    },
    'hiper': {
        component: HiperIcon,
        name: 'Hiper'
    },
    'elo-caixa': {
        component: EloCaixa,
        name: 'Elo Caixa'
    },
    'banescard': {
        component: Banescard,
        name: 'Banescard'
    },
    'credz': {
        component: Credz,
        name: 'Credz'
    },
    'cred-system': {
        component: Credsystem,
        name: 'Credsystem'
    },
    'sorocred': {
        component: Sorocred,
        name: 'Sorocred'
    },
    'cabal': {
        component: Cabal,
        name: 'Cabal'
    },
    'aura': {
        component: Aura,
        name: 'Aura'
    }
}

function CardIcon(props) {
    const Component = icons[props.icon].component
    return (
        <Suspense fallback={<CircularProgress size="1rem"/>}>
            <Component/>
        </Suspense>
    )

}

CardIcon.defaultProps = {
    icon: 'unknown'
}

export default CardIcon
