import {http} from "../utils/http.util";

const httpApiService = http.httpApiService

const authenticate = user => {
    return httpApiService.post(`/checkout/authentication`, user).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

export const authenticationService = {
    authenticate
}
