import './authentication.scss'

import React from 'react'
import AuthenticationForm from "../forms/authentication/authenticationForm";
import {authenticationService} from "../../services/authentication.service";

function Authentication ({callback}) {

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    const authenticate = user => {
        setLoading(true)
        authenticationService.authenticate(user).then(data => {
            callback(data.access_token)
            setError(null)
            setLoading(false)
        }).catch(err => {
            if ("Credentials not found." === err.response.data.message) {
                setError("Usuário ou senha inválidos!")
            } else {
                setError(err.response.data.message)
            }
            setLoading(false)
        })
    }

    return (
        <div className="authentication">
            <div className="modal">
                <AuthenticationForm submit={authenticate} loading={loading} error={error}/>
            </div>
        </div>
    )
}

export default Authentication
