import axios from "axios";

const httpApiService = axios.create({
    baseURL: process.env.REACT_APP_URL_CHECKOUT,
    headers: {
        'Content-Type': 'application/json'
    }
})

const httpGate2all = axios.create({
    baseURL: process.env.REACT_APP_URL_GATE2ALL,
    headers: {
        'Content-Type': 'application/json'
    }
})

const httpGate2allAsync = axios.create({
    baseURL: process.env.REACT_APP_URL_GATE2ALL_ASYNC,
    headers: {
        'Content-Type': 'application/json'
    }
})

export const http = {
    httpApiService,
    httpGate2all,
    httpGate2allAsync,
    axios
}
