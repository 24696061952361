function loadLibraryCybersource(tokenIntention) {
    return new Promise((resolve, reject) => {
        const cyberSource = document.createElement('script');
        cyberSource.type = 'text/javascript';
        cyberSource.async = true;
        cyberSource.src = configureUrl(tokenIntention);
        cyberSource.onload = () => resolve();
        cyberSource.onerror = () => reject();
        document.body.appendChild(cyberSource);
    });
}

function configureUrl(tokenIntention) {
    return "https://h.online-metrix.net/fp/tags.js?org_id={orgId}&session_id={merchantId}{token}"
        .replace("{orgId}", process.env.REACT_APP_CYBERSOURCE_ORG_ID)
        .replace("{merchantId}", process.env.REACT_APP_CYBERSOURCE_MERCHANT_ID)
        .replace("{token}", tokenIntention);
        // .concat("-").concat(uuidUtil.generateUuidV4());
}

export const cybersourceUtil = {
    loadLibraryCybersource
}
