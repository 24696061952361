import {http} from '../utils/http.util';
import GetnetForm from '../components/buyers-forms/getnet.form';
import React from 'react';

const endpoint3ds = '/checkout/3ds'

export const Acquirer = function () {
    this.acquirer = '';
}

Acquirer.prototype = {
    setAcquirer: function (acquirer) {
        this.acquirer = acquirer
    },

    getFormTransaction: function (transaction) {
        return this.acquirer.getFormTransaction(transaction)
    },

    getTransaction: function (transactionId, token) {
        return this.acquirer.getTransaction(transactionId, token)
    }
}

export function getAcquirerFromName(acquirerName) {
    return new Getnet();
}


const Getnet = function () {
    const endpoint = `${endpoint3ds}/getnet`

    this.getTransaction = function (transactionId, token) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '.concat(token)
            }
        }

        return http.httpApiService.get(`${endpoint}/${transactionId}`, config).then(res => {
            return res.data
        }).catch(err => {
            throw err
        })
    }

    this.getFormTransaction = function (transaction) {
        return <GetnetForm transaction={transaction} />
    }
}
