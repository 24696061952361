import './pix-step.scss';

import React from 'react';
import {WebsocketService} from '../../services/websocket.service';
import QrcodePix from '../../components/qrcode-pix/qrcodePix';
import Loading from '../loading/loading';

const webSocketSubscribeTransaction = '/user/topic/transaction';

function PixStep({transactionId, onResult, pixInitialData = null, forceDisconnect = false, onConnected}) {

    const [pix, setPix] = React.useState(pixInitialData)
    const [client] = React.useState(WebsocketService.getInstance(transactionId))

    React.useEffect(() => {
        if (pixInitialData && pixInitialData.qrCode) {
            scrollToQrCode()
        }

        return () => {
            client.disconnect()
        }
    }, [])

    React.useEffect(() => {
        if (!client.isConnected) {
            if (!pix || (pix.status && (pix.status === 0 || pix.status === 1))) {
                client.connect(
                    onConnect,
                    () => {
                    },
                    () => {
                    },
                )
            }
        }
    }, [pix])

    React.useEffect(() => {
        setPix(pixInitialData)
        scrollToQrCode()
    }, [pixInitialData])

    React.useEffect(() => {
        if (forceDisconnect) {
            client.disconnect()
        }
    }, [forceDisconnect])

    // React.useEffect(() => {
    //     if (!pix && !pixInitialData && !client.isConnected) {
    //         const timeToAutoRefresh = 20000
    //         setTimeout(() => {
    //             if (!pix && !pixInitialData && !client.isConnected) {
    //                 pageRefresh()
    //             }
    //         }, timeToAutoRefresh)
    //     }
    // }, [pix, pixInitialData])

    const scrollToQrCode = () => {
        setTimeout(() => {
            window.location.href = window.location.href.replaceAll('#qrcode', '') + '#qrcode'
        }, 200)
    }

    function onConnect() {
        client.subscribe(
            webSocketSubscribeTransaction,
            message => {
                if (message.body) {
                    const pixReceveid = JSON.parse(message.body)
                    if (pixReceveid && (pixReceveid.status !== 0 && pixReceveid.status !== 1)) {
                        onResult(pixReceveid)
                    } else {
                        scrollToQrCode()
                    }
                    setPix(pixReceveid)
                } else {
                    console.warn('Received empty message', message);
                }
            }
        )
        if (onConnected) {
            onConnected()
        }
    }

    // const pageRefresh = () => {
    //     window.location.reload();
    // }

    if (forceDisconnect) {
        return (
            <>
                <h4>Não foi possível carregar as informações sobre o pix.</h4>
            </>
        )
    }

    if (!pix) {
        return (
            <div align={'center'}>
                <Loading/>
            </div>
        )
    }

    return (
        <div>
            <div className='brcode-pix'>
                {
                    pix.status === 0 &&
                    <h4>Aguarde enquanto o QRCode está sendo gerado!</h4>
                }
                {
                    pix.status === 1 &&
                    <div id={'qrcode'}>
                        <QrcodePix brcode={pix['qrCode']}/>
                    </div>
                }
            </div>
        </div>
    )
}

export default PixStep;
