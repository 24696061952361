import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Header from "../components/header/header";

function MainLayout(props) {

    return (
        <React.Fragment>
            <CssBaseline />
            <Container fixed>
                <Typography component="div" style={{minHeight: '100vh'}}>
                    <Header />
                    {props.children}
                </Typography>
            </Container>
        </React.Fragment>
    )
}

export default MainLayout
