import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles({
    header: {
        paddingTop: '60px',
        paddingBottom: '50px'
    },
    img: {
        width: '96px',
        height: 'auto'
    }
})
