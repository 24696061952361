import axios from 'axios'

const getAddress = cep => {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

export const viaCepService = {
    getAddress
}
