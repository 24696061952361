import React from "react";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import {SketchPicker} from "react-color";

function ColorPicker({label, semicolon = true, color, width, height, onChange}) {

    const [picker, setPicker] = React.useState(false)

    const togglePicker = () => {
        setPicker(!picker)
    }

    return (
        <div className="item">
            <label>{`${label}${semicolon ? ":" : ''} `}</label><br/>
            <Button variant="outlined" style={{
                backgroundColor: color,
                width: width,
                height: height
            }}
                    onClick={togglePicker}/>
            <br/><br/>
            {
                picker &&
                <Fade in={picker}>
                    <div style={{textAlign: "center", width: '100%'}}>
                        <SketchPicker color={color}
                                      onChangeComplete={onChange}/>
                    </div>
                </Fade>
            }
        </div>
    )
}

export default ColorPicker
