import './modalBackdrop.scss'

import React from 'react'
import Button from '@material-ui/core/Button';

function ModalBackdrop({isVisible, onClose, title, description, children}) {

    if (!isVisible) {
        return null
    }

    return (
        <div className="modal-backdrop">
            <div className='overflow'>
                <div className="modal-custom">
                    <div className={'close'}>
                        <Button variant={'text'} onClick={onClose}>
                            <img src={'/images/close_24px.png'} alt="close"/>
                        </Button>
                    </div>
                    <div className={'header'}>
                        <h2 className={'title'}>
                            {title}
                        </h2>
                        <p className={'description'}>{description}</p>
                    </div>
                    <div className={'content'}>
                        {
                            children
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalBackdrop
