const withBearer = token => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
}

export const axiosConfigs = {
    withBearer
}
