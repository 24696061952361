import {http} from '../utils/http.util';

const httpApiService = http.httpApiService

const authorize = transactionId => {
    return httpApiService.post(`/checkout/authorize/${transactionId}`).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

const authorizeToken3ds = transactionId => {
    return httpApiService.post(`/checkout/3ds/authorize/${transactionId}`).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

const authorizeTokenization = tokenizationId => {
    return httpApiService.post(`/checkout/tokenization/authorize/${tokenizationId}`).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

const getIntention = (transactionId, accessToken) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(accessToken)
        }
    }

    return httpApiService.get(`/checkout/intention/${transactionId}`, config).then(res => {
        return res
    }).catch(err => {
        throw err
    })
}

const getIntentionTokenization = (tokenizationId, accessToken) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(accessToken)
        }
    }

    return httpApiService.get(`/checkout/intention/tokenization/${tokenizationId}`, config).then(res => {
        return res
    }).catch(err => {
        throw err
    })
}

export const checkoutService = {
    authorize,
    getIntention,
    getIntentionTokenization,
    authorizeToken3ds,
    authorizeTokenization,
}
