import './loading.scss'

import React from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";

function Loading({fullWidth = false, size = '2rem', isPaymentLoading = false}) {
    return (
        <div className={fullWidth ? 'loading-full' : 'loading'}>
            <CircularProgress size={size} color={"secondary"} />
            { isPaymentLoading && <p>Processando pagamento...</p>}
        </div>

    )
}

export default Loading
