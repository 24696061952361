import {makeStyles} from "@material-ui/styles";

export const useStyles = theme => makeStyles({
    h1: {
        color: theme.palette.text.primary,
        fontSize: '2.6rem',
        lineHeight: '40px',
        marginTop: 0
    },
    a: {
        '&:visited': {
            color: theme.palette.text.primary
        },
        '&:link': {
            color: '#5b5bf3',
            textDecoration: 'none'
        },
        '&:hover': {
            opacity: '0.9'
        }
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: '65vh'
    }
})
