import './intention-customize.scss'
import { useStyles } from "./intention.style";

import React from "react"
import Grid from "@material-ui/core/Grid";
import currencyFormatter from "currency-formatter"
import Terms from "../../components/terms/terms";
import { makeStyles, ThemeProvider, withTheme } from "@material-ui/styles";
import Ticket from "../../components/ticket/ticket";
import IdentificationForm from "../../components/forms/identification/identificationForm";
import clsx from "clsx";
import { createMuiTheme } from "@material-ui/core";
import PaymentForm from "../../components/forms/payment/paymentForm";
import { brandsAvailable } from "../../utils/brands-availables";
import { transactionStatus } from "../../utils/transaction-status.util";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import Fab from "@material-ui/core/Fab";
import EditIcon from '@material-ui/icons/Edit';
import Divider from "@material-ui/core/Divider";
import { themes } from "../../themes/themes";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ColorPicker from "../../components/colorPicker/ColorPicker";
import { dateUtil } from "../../utils/date-util";
import StepperWrapped from "../../components/stepper/stepperWrapped";
import Authentication from "../../components/authentication/authentication";
import { themeService } from "../../services/theme.service";
import { storage } from "../../utils/session-storage.util";
import { fileUtils } from "../../utils/file.util";
import Loading from "../../components/loading/loading";

function getSteps() {
    return ['Identificação', 'Pagamento'];
}

const useNewStyles = makeStyles({
    list: {
        padding: 10
    },
    fullList: {
        width: 'auto',
    },
    uploadDrag: {
        width: 220,
        height: 'auto'
    }
});

const themeSelected = process.env.REACT_APP_THEME

function IntentionCustomize({ setTheme, ...props }) {

    const optionsThemeDefault = themes[themeSelected].options
    const fileInput = React.createRef()

    const theme = props.theme
    const classes = useStyles(theme)()
    const classesNew = useNewStyles()

    const [activeStep, setActiveStep] = React.useState(1)
    const [intention] = React.useState(props.intention)
    const [identification, setIdentification] = React.useState(null)
    const [transactionResult] = React.useState(null)
    const [acceptedTerms, setAcceptedTerms] = React.useState(true)
    const [drawerCustomize, setDrawerCustomize] = React.useState(false)
    const [themeCustom, setThemeCustom] = React.useState(optionsThemeDefault)
    const [token, setToken] = React.useState(storage.getToken())
    const [loadedTheme, setLoadedTheme] = React.useState(false)
    const [nameLogo, setNameLog] = React.useState("Nenhum arquivo selecionado.")
    const [loading, setLoading] = React.useState(false)
    const [loadingTheme, setLoadingTheme] = React.useState(!!token)
    const [resultSaveTheme, setResultSaveTheme] = React.useState('')

    const steps = getSteps();

    const loadTheme = () => {
        themeService.getUrlTheme(token).then(res => {
            const token = res.headers.authorization
            setLoadedTheme(true)
            setToken(token)
            const url = res.data.url
            if (url) {
                themeService.getTheme(url).then(data => {
                    setThemeCustom(data)
                    setLoadingTheme(false)
                }).catch(err => {
                    console.log(err)
                    setLoadingTheme(false)
                })
            } else {
                setLoadingTheme(false)
            }
        }).catch(err => {
            if (err.response.status === 401) {
                storage.clearSession()
                setToken(null)
            }
            setLoadingTheme(false)
        })
    }

    React.useEffect(() => setTheme(themeCustom), [themeCustom])

    React.useEffect(() => {
        if (token && !loadedTheme) {
            loadTheme()
        }
    }, [loadedTheme, token])

    React.useEffect(() => {
        if (token && (storage.getToken() !== token)) {
            storage.saveToken(token)
        }
    }, [token])

    if (loadingTheme) {
        return <Loading fullWidth />
    }

    if (!token) {
        return <Authentication callback={token => {
            setToken(token)
            setLoadingTheme(true)
        }} />
    }

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const submitIdentification = identificationData => {
        setIdentification(identificationData)
        handleNextStep()
    }

    const submitPayment = paymentData => {
        console.log(paymentData)
    }

    let title = `Pague R$${currencyFormatter.format(intention.amount, {
        locale: 'pt-BR',
        symbol: ''
    })} para ${intention.client.name} inserindo as informações abaixo.`

    if (transactionResult) {
        title = transactionStatus[transactionResult.status].title
    }

    const brandsAvailableReturned = brandsAvailable(intention ? intention.brands : [])

    const getContentByStep = step => {
        if (step === 0) {
            return (<IdentificationForm submit={submitIdentification} identification={identification}
                customer={intention.customer} loadingPayment={false}
                paymentMethodSelected={"card"}
                isRequired={true} />)
        } else if (step === 1) {
            return <PaymentForm submit={submitPayment} brands={brandsAvailableReturned} intention={intention}
                loading={loading} cancelPayment={handleBackStep} />
        } else {
            return null
        }
    }

    const onChangeBackground = color => {
        const { palette } = themeCustom
        palette.background.default = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangeBackgroundPaper = color => {
        const { palette } = themeCustom
        palette.background.paper = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangePrimaryColor = color => {
        const { palette } = themeCustom
        palette.primary.main = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangeSecondaryColor = color => {
        const { palette } = themeCustom
        palette.secondary.main = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangePrimaryTextColor = color => {
        const { palette } = themeCustom
        palette.text.primary = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangeSecondaryTextColor = color => {
        const { palette } = themeCustom
        palette.text.secondary = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangeHintTextColor = color => {
        const { palette } = themeCustom
        palette.text.hint = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangeDisabledTextColor = color => {
        const { palette } = themeCustom
        palette.text.disabled = color.hex
        const result = { ...themeCustom, palette }
        setThemeCustom(result)
    }

    const onChangeLinkLogo = () => {
        const file = fileInput.current.files[0]
        setNameLog(fileInput.current.files[0].name)
        if (file) {
            fileUtils.toBase64(file).then(fileBase64 => {
                const result = { ...themeCustom, mainLogo: fileBase64 }
                setThemeCustom(result)
            })
        }
    }

    const triggerUploadLogo = () => {
        document.getElementById('upload-logo').click()
    }

    const saveTheme = () => {
        setResultSaveTheme('')
        setLoading(true)
        themeService.save(themeCustom, token).then(res => {
            setToken(res.headers.authorization)
            setLoading(false)
            setResultSaveTheme('Tema salvo!')
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setResultSaveTheme('Não foi possível salvar o tema!')
        })
    }

    const contentCustomize = () => (
        <div
            className={clsx(classesNew.list)}
            role="presentation"
        >
            <div>
                <div style={{ textAlign: "center" }}>
                    <h3>Alterar</h3>
                </div>

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Cor primária" width={220} height={30} onChange={onChangePrimaryColor}
                    color={themeCustom.palette.primary.main} />

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Cor secundária" width={220} height={30} onChange={onChangeSecondaryColor}
                    color={themeCustom.palette.secondary.main} />

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Background" width={220} height={30} onChange={onChangeBackground}
                    color={themeCustom.palette.background.default} />

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Background modal" width={220} height={30} onChange={onChangeBackgroundPaper}
                    color={themeCustom.palette.background.paper} />

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Texto cor primária" width={220} height={30} onChange={onChangePrimaryTextColor}
                    color={themeCustom.palette.text.primary} />

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Texto cor secundária" width={220} height={30}
                    onChange={onChangeSecondaryTextColor}
                    color={themeCustom.palette.text.secondary} />

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Cor de links" width={220} height={30} onChange={onChangeHintTextColor}
                    color={themeCustom.palette.text.hint} />

                <Divider style={{ marginBottom: '15px' }} />

                <ColorPicker label="Texto desabilitado" width={220} height={30} onChange={onChangeDisabledTextColor}
                    color={themeCustom.palette.text.disabled} />

                <Divider style={{ marginBottom: '15px' }} />

                <Button onClick={triggerUploadLogo} type="button" color="default" variant="contained"
                    style={{ width: 220 }}>
                    Carregar Logo
                </Button>
                <br />
                <label style={{ fontSize: 12 }}>{nameLogo}</label>

                <input type="file" className="hidden-upload" id="upload-logo" onChange={onChangeLinkLogo} ref={fileInput} />
                <br /><br />

                <div align="center" style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <Button onClick={saveTheme} disabled={loading} type="button" color={"primary"} variant={"contained"}>
                        Salvar
                    </Button>
                    {
                        loading && <Loading size="1.3rem" />
                    }
                </div>
                <br />
                {
                    <div align="center">
                        <label>{resultSaveTheme}</label>
                    </div>
                }
            </div>
        </div>
    );

    const expireInHours = dateUtil.getDateHoursFromExpiry(intention ? intention.expireIn : undefined)
    const expireInDay = dateUtil.getDateFromExpiry(intention ? intention.expireIn : undefined)

    return (
        <div className={classes}>
            <div className="intention-customize">
                <Fab color="primary" variant="extended" aria-label="edit" className="fab-button-customize"
                    onClick={() => setDrawerCustomize(true)}>
                    <EditIcon /> Customizar
                </Fab>
                <ThemeProvider theme={createMuiTheme()}>
                    <Drawer anchor={'left'} open={drawerCustomize} variant="persistent">
                        <div className={"drawer-close"}>
                            <Button onClick={() => setDrawerCustomize(false)} fullWidth>
                                <ArrowBackIcon />
                            </Button>
                        </div>
                        <div className={"drawer-customize"}>
                            {contentCustomize()}
                        </div>
                    </Drawer>
                </ThemeProvider>
            </div>
            <Grid container spacing={5} justify={"space-between"}>
                <Grid item md={5} sm={6} xs={12}>
                    <div className="principal-description">
                        <h1 className={classes.h1}>{title}</h1>
                    </div>
                    <div>
                        {
                            !transactionResult &&
                            <div>
                                {
                                    expireInHours &&
                                    <Paper>
                                        <Grid container justify="space-between" alignItems="flex-start">
                                            <Grid item xs={2}>
                                                <div style={{ textAlign: "center" }}>
                                                    <InfoOutlinedIcon color="primary" style={{ marginTop: 20 }} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Grid container justify="center" alignItems="center">
                                                    <Grid item>
                                                        <p style={{ paddingRight: 14 }}>
                                                            <strong>
                                                                {`Este link é válido para apenas um pagamento e estará disponível até
                                                    às ${expireInHours} do dia ${expireInDay}.`}
                                                            </strong>
                                                        </p>
                                                    </Grid>
                                                    <Grid item>
                                                        <p style={{ paddingRight: 14, marginTop: 0 }}>
                                                            Passado este período, será necessário solicitar um novo link
                                                            ao
                                                            estabelecimento.
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                }
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={2}>
                                        <div style={{ textAlign: "center" }}>
                                            <Checkbox value={acceptedTerms} disabled={acceptedTerms}
                                                defaultChecked={acceptedTerms}
                                                onChange={e => setAcceptedTerms(e.target.checked)} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <p className={classes.p}>Ao utilizar este serviço, declaro que sou maior de
                                        idade e
                                            aceito os <a
                                                className={classes.a}
                                                rel="noopener noreferrer"
                                                href="https://openfiles.paygo.com.br/Termo_de_Uso_e_Politica_de_Privacidade_v3.2.pdf"
                                                target="_blank">termos de uso e condições</a>.</p>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>
                    {
                        acceptedTerms &&
                        <div>
                            <br />
                            <StepperWrapped activeStep={activeStep} steps={steps}
                                visible={!transactionResult} />
                            <br />
                            {
                                !transactionResult &&
                                <div>
                                    {
                                        getContentByStep(activeStep)
                                    }
                                    <Terms />
                                </div>
                            }
                        </div>
                    }
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Ticket brands={brandsAvailableReturned} intention={intention}
                        finish={transactionResult && true}
                        finishStatus={transactionResult ? transactionResult.status : undefined}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default withTheme(IntentionCustomize)
