export const intentionMock = {
    tokenIntention: 'ec8df78a-fbea-4e7a-aad6-3f80602d12eb',
    description: 'Customize form.',
    formattedAmount: 10.00,
    referenceId: '100001',
    status: 1,
    amount: 99.99,
    client: {
        name: 'Loja',
        tradingName: 'Nome fantasia',
        document: '99.999.99/9999-99'
    },
    customer: {
        name: 'Nome Comprador',
    },
    payment: {
        pix: {
            provider: 'C6BANK',
            key: [ 'RANDOM_KEY' ]
        },
        card: {
            type: 0,
            capture: true,
            installments: 4,
            fixedInstallments: false,
            interestType: 3,
            authenticate: 3,
            saveCard: false,
            recurrent: false
        }
    },
    brands: [
        {
            name: 'MASTERCARD'
        },
        {
            name: 'VISA'
        }
    ],
    paymentMethods: [ 'pix' ],
    fraudAnalysis: {
        'analyze': false,
        'publicKey': null
    },
    'isSub': false
}
