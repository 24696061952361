export const tokenizationStatus = {
    '-2': {
        title: 'Sessão expirada',
        subTitle: () => `Para salvar o cartão, atualize a página.`
    },
    0: {
        title: 'Cartão salvo',
        subTitle: () => `Seu cartão foi salvo com sucesso.`
    },
    1: {
        title: 'Erro ao salvar cartão',
        subTitle: () => `Ocorreu algum erro ao salvar o seu cartão, tente novamente mais tarde.`
    },
    2: {
        title: 'Cartão salvo e validado',
        subTitle: () => `Seu cartão foi salvo e validado com sucesso.`
    },
    3: {
        title: 'Erro ao validar cartão',
        subTitle: () => `As tentativas de validação foram excedidas.`
    }
}
