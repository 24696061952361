import {http} from "../utils/http.util";
import {axiosConfigs} from "../utils/axios-configs.util";

const httpGate2all = http.httpGate2all

const transaction = (transaction, token) => {
    const config = axiosConfigs.withBearer(token)

    return httpGate2all.post('/transactions', transaction, config).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

const tokenization = (tokenization, token) => {
    const config = axiosConfigs.withBearer(token)

    return httpGate2all.post('/tokenization', tokenization, config).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

const validateCard = (tokenizationId, validateCard, token) => {
    const config = axiosConfigs.withBearer(token)

    return httpGate2all.put(`/tokenization/validate-card/${tokenizationId}`, validateCard, config).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

export const gate2allService = {
    transaction,
    tokenization,
    validateCard,
}
