import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Draggable from 'react-draggable';
import Paper from "@material-ui/core/Paper";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function ConfirmDialog(
    {
        visible = true,
        title = '',
        contentText = '',
        onOk = () => {},
        onCancel = () => {},
        cancelText = 'Cancelar',
        okText = 'Confirmar'
    }
) {

    return (
        <Dialog open={visible} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={onCancel}>{cancelText}</Button>
                <Button color="primary" onClick={onOk}>{okText}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog
