import './cardTile.scss'

import React from 'react'
import {withTheme} from '@material-ui/styles';

function CardTile({onClick, icon, TitleComponent, title, description, value, actualValue, ...props}) {

    const theme = props.theme;

    return (

        <div className={`card-tile ${value === actualValue ? 'selected' : ''}`} onClick={onClick}>
            <div className="icon">
                {icon}
            </div>
            <div className="description">
                <div>
                    {
                        title &&
                        <h3>{title}</h3>
                    }
                    {
                        TitleComponent &&
                        <TitleComponent />
                    }
                    <h5>{description}</h5>
                </div>
            </div>
            <div className="radio">
                <div className="checkbox"
                     style={{borderColor: value === actualValue ? theme.palette.primary.main : 'rgba(128, 128, 128, 0.44)'}}>
                    <div className="checkbox-content"
                         style={{backgroundColor: value === actualValue ? theme.palette.primary.main : 'transparent'}}/>
                </div>
            </div>
        </div>

    );
}

export default withTheme(CardTile)
