import React from 'react'
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import {makeStyles, withStyles} from "@material-ui/styles";
import {StepConnector} from "@material-ui/core";
import clsx from "clsx";
import {Check} from "@material-ui/icons";

const useStyles = makeStyles({
    stepper: {
        background: 'none',
        textAlign: 'left',
        padding: '24px 0'
    }
})

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#8BC34A',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#8BC34A',
        },
    },
    line: {
        borderColor: '#DCE1EA',
        borderTopWidth: 3,
        borderRadius: 1,
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#8BC34A',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#8BC34A',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

/**
 * @return {null}
 */
function StepperWrapped({activeStep, steps, visible}) {

    const classes = useStyles()

    if (visible) {
        return (
            <Stepper alternativeLabel className={classes.stepper} activeStep={activeStep}
                     connector={<QontoConnector/>}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        )
    }

    return null
}

export default StepperWrapped
