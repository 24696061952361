import React from "react"
import {useStyles} from "./headerStyle"
import {withTheme} from "@material-ui/styles";

function Header(props) {
    const theme = props.theme
    const classes = useStyles()
    return (
        <div className={classes.header}>
            <img className={classes.img} src={theme.mainLogo} alt={"logo"}/>
        </div>
    )
}

export default withTheme(Header)
