import './infoPix.scss'
import ModalBackdrop from '../modal-backdrop/modalBackdrop';
import React from 'react';
import Button from '@material-ui/core/Button';

function InfoPix({visible, onClose, onConfirm}) {

    return (
        <ModalBackdrop isVisible={visible} title={'Como funciona o Pix?'}
                       description={'Entenda um pouco mais como você pode pagar usando Pix.'}
                       onClose={onClose}>
            <div className='info-pix'>
                <div>
                    <div className="steps">
                        <div className='step'>
                            <h4>1. Pague com Pix</h4>
                            <p>Agora você consegue fazer seu pagamento de forma instantânea.</p>
                        </div>
                        <div className='step'>
                            <h4>2. QR Code</h4>
                            <p>Faça a leitura do QR Code através do app da sua instituição.</p>
                        </div>
                        <div className='step'>
                            <h4>3. Copie e cole</h4>
                            <p>Você também pode copiar e colar a chave Pix no app da sua instituição:</p>
                            <ul>
                                <li>Abra o app da sua instituição</li>
                                <li>Selecione a opção ”Pix copia e cola"</li>
                                <li>Cole a chave</li>
                                <li>E finalize o pagamento.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row-end">
                        <div className="actions">
                            <hr/>
                            <Button variant={'contained'} color={'secondary'} className="bt-radius" onClick={onConfirm}>Continuar pagamento</Button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBackdrop>
    )
}

export default InfoPix
