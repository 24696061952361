import './ticket.scss'

import React from 'react'
import currencyFormatter from 'currency-formatter'
import Lottie from 'react-lottie'
import { withTheme } from '@material-ui/styles';
import { useStyles } from './ticketStyle';
import CardIcon from '../cardIcon/cardIcon';
import { intentErrors } from '../../utils/intent-errors.util';
import { transactionStatus } from '../../utils/transaction-status.util';
import * as approveAnimation from './approveAnimation.json'
import * as declinedAnimation from './declinedAnimation.json'
import Fade from '@material-ui/core/Fade';

function Ticket({ brands, ...props }) {

    const theme = props.theme
    const classes = useStyles(theme)()
    const error = props.error
    const finish = props.finish
    const finishStatus = props.finishStatus
    const descriptionPaymentMethod = props.descriptionPaymentMethod
    const errorStatus = props.errorStatus
    const intention = props.intention
    const isFallbackFromWebSocket = props.isFallbackFromWebSocket

    const [animationPlay, setAnimationPlay] = React.useState(false)

    React.useEffect(() => {
        if (finish) {
            setAnimationPlay(true)
        } else {
            setAnimationPlay(false)
        }
    }, [finish])

    const approveAnimationConfig = {
        loop: false,
        autoplay: true,
        animationData: approveAnimation.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const declineAnimationConfig = {
        loop: false,
        autoplay: true,
        animationData: declinedAnimation.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const completeAnimation = () => {
        setAnimationPlay(false)
    }

    return (
        <div className={'box-wrap'}>
            <div className={'box-info'}
                style={{
                    backgroundColor: theme.palette.background.paper
                }}>
                <div className={'info info-client'}>
                    <div>
                        {
                            (errorStatus !== -1 || isFallbackFromWebSocket) &&
                            <React.Fragment>
                                <div className="referenceId">{
                                    intention.referenceId &&
                                    <label>
                                        <strong>Pedido: </strong> {intention.referenceId}
                                    </label>
                                }</div>
                                <div className="info-data">
                                    <label>Dados do emissor</label>
                                    <h2 className={classes.h3}>{intention.client.tradingName}</h2>
                                    <h5 style={{margin: 0}}><strong>{intention.client.agent === "PJ" ? intention.client.name : null}</strong></h5>
                                    <label className="document">{intention.client.document}</label>
                                    <label className="description">Descrição do pagamento</label>
                                    <label><strong>{intention.description}</strong></label>
                                </div>
                            </React.Fragment>
                        }
                        {
                            errorStatus === -1 && !isFallbackFromWebSocket &&
                            <h2 className={classes.h3}>Link inexistente!</h2>
                        }
                    </div>
                </div>
                <div className={'info info-payment'}>
                    {
                        animationPlay &&
                        <div>
                            {
                                (finishStatus === 5 || finishStatus === 6) &&
                                <Lottie options={approveAnimationConfig}
                                    width={150}
                                    height={150}
                                    eventListeners={[{ eventName: 'complete', callback: completeAnimation }]} />
                            }
                            {
                                finishStatus !== 5 && finishStatus !== 6 &&
                                <Lottie options={declineAnimationConfig}
                                    width={150}
                                    height={150}
                                    eventListeners={[{ eventName: 'complete', callback: completeAnimation }]} />
                            }
                        </div>
                    }
                    {
                        (!error || (error && isFallbackFromWebSocket)) && !animationPlay &&
                        <div>
                            {
                                finish &&
                                <div>
                                    {
                                        (finishStatus === 5 || finishStatus === 6 || isFallbackFromWebSocket) &&
                                        <div>
                                            <label>Valor total do pagamento</label>
                                            <Fade in={finish}>
                                                <h1 className={classes.valueToPay}>
                                                    <span>R$</span>{currencyFormatter.format(intention.formattedAmount, {
                                                        locale: 'pt-BR',
                                                        symbol: ''
                                                    })}</h1>
                                            </Fade>
                                            <label className={'accepted-brands'}>{descriptionPaymentMethod}</label>
                                        </div>
                                    }
                                    {
                                        (finishStatus !== 5 && finishStatus !== 6) &&
                                        <div>
                                            {
                                                finishStatus !== 0 &&
                                                <label>Não foi possível efetuar o pagamento.</label>
                                            }
                                            <p style={{ margin: 0 }}>{transactionStatus[finishStatus].title}</p>
                                        </div>
                                    }
                                </div>
                            }
                            {
                                !finish &&
                                <div>
                                    <label>Valor a ser pago</label>
                                    <h1 className={classes.valueToPay}>
                                        <span>R$</span>{currencyFormatter.format(intention.formattedAmount, {
                                            locale: 'pt-BR',
                                            symbol: ''
                                        })}</h1>

                                    {
                                        brands.length > 0 &&
                                        <div>
                                            <label className={'accepted-brands'}>Bandeiras aceitas</label>
                                            <div className={'brands'}>
                                                {
                                                    brands.map(brand => {
                                                        return (
                                                            <div key={brand} className={'brand'}>
                                                                <CardIcon icon={brand} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        brands.length === 0 &&
                                        <label className={'accepted-brands'}>Nenhuma bandeira de cartão aceita.</label>
                                    }

                                </div>
                            }
                        </div>
                    }
                    {
                        (error && !isFallbackFromWebSocket) &&
                        <div>
                            <label>Não é possível efetuar o pagamento.</label>
                            <p style={{ margin: 0 }}>{intentErrors[errorStatus].whatsHappen}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

Ticket.defaultProps = {
    brands: []
}

export default withTheme(Ticket)
