import './../../custom.scss'
import './validate-card.scss'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import jwt from 'jsonwebtoken'
import { useStyles } from './validate-card.style'
import Terms from '../../components/terms/terms'
import { withTheme } from '@material-ui/styles'
import ValidateCardForm from '../../components/forms/validate-card/validateCardForm'
import { checkoutService } from '../../services/checkout.service'
import { tokenizationIntentErrors } from '../../utils/tokenization-intent-errors.util'
import { gate2allService } from '../../services/gate2all.service'
import { tokenizationStatus } from '../../utils/tokenization-status.util'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { themeService } from '../../services/theme.service'
import Loading from '../../components/loading/loading'
import ConfirmDialog from '../../components/confirmDialog/confirmDialog';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

function ValidateCard(props) {

    const theme = props.theme
    const classes = useStyles(theme)()

    const tokenizationId = props.match.params.token

    const [intention, setIntention] = React.useState(null)
    const [accessToken, setAccessToken] = React.useState(null)
    const [error, setError] = React.useState(false)
    const [errorStatus, setErrorStatus] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [loadingTokenization, setLoadingTokenization] = React.useState(false)
    const [tokenizationResult, setTokenizationResult] = React.useState(null)
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [infoSnackbar, setInfoSnackbar] = React.useState('')
    const [errorTokenization, setErrorTokenization] = React.useState('')
    const [loadedTheme, setLoadedTheme] = React.useState(false)
    const [confirmRenewSession, setConfirmRenewSession] = React.useState(false)
    const [idTimeoutDialog, setIdTimeoutDialog] = React.useState(0)
    const [idTimeoutAutoCancel, setIdTimeoutAutoCancel] = React.useState(0)

    // carrega a intenção de tokenização com o api-service
    React.useEffect(() => {
        if (tokenizationId) {
            // verifica se o token existe e gera uma autorização
            checkoutService.authorizeTokenization(tokenizationId).then(data => {
                // consulta os dados da intenção
                checkoutService.getIntentionTokenization(tokenizationId, data['access_token']).then(res => {
                    if (res.data && res.data.status === 0) {
                        setErrorConfig(0);
                    }

                    if (res.data && res.data.status === 1 && res.data.validateCard) {
                        setErrorConfig(-3);
                    }

                    if (res.headers.authorization) {
                        const intentionResult = res.data

                        setIntention(intentionResult)
                        setAccessToken(res.headers.authorization)
                    }

                    setLoading(false)
                }).catch(err => {
                    setErrorConfig(-1)
                })
            }).catch(err => {
                setErrorConfig(-1)
            })
        }
    }, [tokenizationId])

    // verifica se existe tema para esse cliente, caso contrário mantém o default
    React.useEffect(() => {
        if (!loadedTheme && intention) {
            if (intention.urlStyleCheckout) {
                themeService.getTheme(intention.urlStyleCheckout).then(data => {
                    setLoadedTheme(true)
                    props.setTheme(data)
                }).catch(err => {
                    console.log(err)
                    setLoadedTheme(true)
                })
            } else {
                setLoadedTheme(true)
            }
        }
    }, [intention, loadedTheme])

    // requisita novo token e renova a chave de criptografia após confirmação do usuário
    const onConfirmRenewSession = () => {
        clearTimeout(idTimeoutAutoCancel)
        clearTimeout(idTimeoutDialog)
        setConfirmRenewSession(false)
        renewAccessToken(null)
    }

    // altera o tokenizationResult para não ser mais possível salvar o cartão
    const onCancelRenewSession = () => {
        clearTimeout(idTimeoutAutoCancel)
        clearTimeout(idTimeoutDialog)
        setConfirmRenewSession(false)
        setTokenizationResult({ status: -2 })
    }

    const setErrorConfig = (status) => {
        setError(true)
        setLoadedTheme(true)
        setErrorStatus(status)
        setLoading(false)
    }

    // cria o dialog de confirmação para renovar sessão
    React.useEffect(() => {
        if (!tokenizationResult && !error && accessToken) {
            const token = jwt.decode(accessToken)
            const myDate = new Date(token.exp * 1000);
            const today = new Date()
            const difference = myDate.getTime() - today.getTime()
            const threeMinutes = 60000 * 3
            const timeToOpen = difference - threeMinutes
            const idTimeoutDialog = setTimeout(() => {
                setConfirmRenewSession(true)
                const idAutoCancel = setTimeout(() => {
                    onCancelRenewSession()
                }, threeMinutes - 1000)
                setIdTimeoutAutoCancel(idAutoCancel)
            }, timeToOpen)
            setIdTimeoutDialog(idTimeoutDialog)
        }
    }, [accessToken, tokenizationResult, error])

    // mostra o loading se não tiver processado as informações
    if (loading || !loadedTheme) {
        return <Loading fullWidth />
    }

    // fecha a notificação
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false)
    }

    const createValidateCard = amount => {
        return Promise.resolve({
            amount: amount
        })
    }

    const submitValidateCard = amount => {
        setLoadingTokenization(true)
        setLoading(true)
        createValidateCard(amount).then(validateCard => {
            sendValidateCard(tokenizationId, validateCard, data => {
                defineStatusTokenizationResultToSuccess(data).then(data => {
                    updateTokenizationResult(data)
                })
            })
        })
    }

    const sendValidateCard = (tokenizationId, validateCard, onFinish) => {
        setLoadingTokenization(true)
        setLoading(true)
        gate2allService.validateCard(tokenizationId, validateCard, accessToken).then(data => {
            onFinish(data)
            if (intention.redirectUrl) {
                setTimeout(() => {
                    setSnackbarOpen(true)
                    setInfoSnackbar(`Você está sendo redirecionado para: ${intention.redirectUrl}`)
                }, 1000)
                setTimeout(() => {
                    window.open(intention.redirectUrl, '_top')
                }, 4000)
            }
        }).catch(error => {
            setLoadingTokenization(false)
            setLoading(false)

            if (errorIsBadRequest(error)) {
                renewAccessToken(error).then(() => {
                    checkoutService.getIntentionTokenization(tokenizationId, accessToken).then(res => {
                        if (res.data && res.data.status === 0) {
                            defineStatusTokenizationResultToFailure(res.data).then(data => {
                                updateTokenizationResult(data)
                            })
                        }
                    }).catch(error => {
                        errorThrow(error)
                    })
                }).catch(error => {
                    errorThrow(error)
                })
            }

            if (!errorIsBadRequest(error)) {
                errorThrow(error)
            }
        })
    }

    const errorIsBadRequest = error => {
        return error && error.response.status && error.response.status == 400
    }

    const errorThrow = (error) => {
        setErrorTokenization('Não foi possível validar o cartão, tente novamente mais tarde!')
        renewAccessToken(error)
    }

    const renewAccessToken = (err) => {
        return new Promise((resolve, reject) => {
            checkoutService.authorizeTokenization(tokenizationId).then(data => {
                setAccessToken(data['access_token'])
                if (err && err.response) {
                    if ((parseInt(err.response.status) / 100) === 5) {
                        setErrorTokenization('Problemas na comunicação com o servidor.')
                        setSnackbarOpen(true)
                    } else {
                        if (err.response.data) {
                            setErrorTokenization(err.response.data.error.message)
                            setSnackbarOpen(true)
                        }
                    }
                }
                resolve()
            }).catch(() => {
                setTokenizationResult({ status: -2 })
                reject()
            })
        });
    }

    const defineStatusTokenizationResultToSuccess = tokenizationResult => {
        return new Promise((resolve, reject) => {
            tokenizationResult.status = 2
            resolve(tokenizationResult)
        });
    }

    const defineStatusTokenizationResultToFailure = tokenizationResult => {
        return new Promise((resolve, reject) => {
            tokenizationResult.status = 3
            resolve(tokenizationResult)
        });
    }

    const updateTokenizationResult = tokenizationResult => {
        setTokenizationResult(tokenizationResult)
        setLoadingTokenization(false)
        setLoading(false)
    }

    let title = error ? `${tokenizationIntentErrors[errorStatus].whatsHappen}` : `Validação do cartão`
    let subTitle = error ? `${tokenizationIntentErrors[errorStatus].whatToDo()}` :
        `Geramos uma transação de validação na sua fatura, confirme o valor abaixo para concluírmos a validação do cartão.`

    if (tokenizationResult) {
        title = tokenizationStatus[tokenizationResult.status].title
        subTitle = tokenizationStatus[tokenizationResult.status].subTitle()
    }

    return (
        <div className={classes}>
            <ConfirmDialog onOk={onConfirmRenewSession} visible={confirmRenewSession}
                onCancel={onCancelRenewSession}
                title="Deseja renovar sua sessão?"
                contentText={`Sua sessão vai expirar às 
                           ${accessToken
                        ? new Date(jwt.decode(accessToken).exp * 1000).toLocaleTimeString()
                        : ''
                    }`} />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={errorTokenization ? 'error' : 'info'}>
                    {errorTokenization ? errorTokenization : infoSnackbar}
                </Alert>
            </Snackbar>
            <Grid container spacing={5} justify={'space-between'} className='intetion-content-custom'>
                <Grid item md={5} sm={6} xs={12}>
                    <div className="principal-description">
                        <h1 className={classes.h1}>{title}</h1>
                        { !error && <p>{subTitle}</p> }
                    </div>
                    {
                        <div>
                            <br/>
                            {
                                !error && !tokenizationResult &&
                                <div>
                                    <ValidateCardForm submit={submitValidateCard} loading={loadingTokenization} />
                                    <Terms />
                                </div>
                            }
                        </div>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default withTheme(ValidateCard)
