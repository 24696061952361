import {http} from "../utils/http.util";
import {axiosConfigs} from "../utils/axios-configs.util";

const httpGate2allAsync = http.httpGate2allAsync

const transaction = (transaction, token) => {
    const config = axiosConfigs.withBearer(token)

    return httpGate2allAsync.post('/transactions', transaction, config).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

export const gate2allAsyncService = {
    transaction
}
