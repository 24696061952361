import React from 'react'
import MaskedInput from "react-text-mask";

function CardValidateInput(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
            showMask
            guide={false}
        />
    );
}

export default CardValidateInput
