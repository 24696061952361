import './validateCardForm.style.scss'
import React from 'react'
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    withTheme
} from '@material-ui/core';
import Loading from '../../loading/loading';
import { NumberFormatBase } from 'react-number-format';

function ValidateCardForm({submit, ...props}) {

    const loading = props.loading

    let amount = '0'
    let inputNumberRef = null

    const submitForm = e => {
        e.preventDefault()
        submit(amount)
    }

    const onChangeAmount = e => {
        amount = e.target.value.replace(/[^0-9]+/g, '')
    }

    const customNumberFormat = props => {
        const format = (numStr) => {
            if (numStr === '') return '';
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                decimalScale: 2,
            }).format(numStr / 100);
        };
        return <NumberFormatBase {...props} format={format} />;
    }

    return (
        <form onSubmit={submitForm}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="amount">Valor</InputLabel>
                        <Input id="amount" autoComplete="cc-number"
                            inputRef={ref => inputNumberRef = ref}
                            onChange={onChangeAmount}
                            autoFocus={true}
                            inputComponent={customNumberFormat} />
                    </FormControl>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div align="center" style={{display: 'flex', alignItems: 'left', justifyContent: 'left'}} t={8}>
                        <Button
                            disabled={loading}
                            className="button"
                            type="submit"
                            variant={'contained'}>
                            Validar
                        </Button>
                        {
                            loading && <Loading size="1.3rem"/>
                        }
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

export default withTheme(ValidateCardForm)
