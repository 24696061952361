export const transactionStatus = {
    '-11': {
        title: 'Pagamento não concluído.',
        subTitle: () => `Você pode tentar novamente utilizando um novo cartão ou outra forma de pagamento, escolha uma das opções:`
    },
    '-7': {
        title: 'Pagamento não autorizado.',
        subTitle: () => `Você pode tentar novamente utilizando um novo cartão ou outra forma de pagamento, escolha uma das opções:`
    },
    '-2': {
        title: 'Sessão expirada para efetuar o pagamento.',
        subTitle: () => `Para realizar o pagamento, atualize a página novamente.`
    },
    0: {
        title: 'Aguardando pagamento.',
        subTitle: () => `Caso já tenha efetuado o pagamento, desconsidere o aviso.`
    },
    1: {
        title: 'Aguardando pagamento.',
        subTitle: () => `Caso já tenha efetuado o pagamento, desconsidere o aviso.`
    },
    3: {
        title: 'Pagamento em análise.',
        subTitle: store => `O Pagamento está sendo analisado. Para um novo pagamento ou informações entre em contato com ${store}.`
    },
    5: {
        title: 'Pagamento Aprovado.',
        subTitle: () => `O pagamento foi efetuado com sucesso.`
    },
    '5.1': {
        title: 'Pagamento em processamento.',
        subTitle: () => `O pagamento ainda está sendo processado. Aguarde.`
    },
    6: {
        title: 'Pagamento Aprovado.',
        subTitle: () => `O pagamento foi efetuado com sucesso.`
    },
    7: {
        title: 'Pagamento não autorizado.',
        subTitle: store => `Entre em contato com ${store} e solicite um novo link de pagamento.`
    },
    8: {
        title: 'Pagamento em análise de cancelamento.',
        subTitle: store => `O Pagamento está em processo de cancelamento. Para um novo pagamento entre em contato com ${store}.`
    },
    9: {
        title: 'Pagamento não autorizado.',
        subTitle: store => `Entre em contato com ${store} e solicite um novo link de pagamento.`
    },
    10: {
        title: 'Pagamento em análise.',
        subTitle: store => `O Pagamento está sendo analisado. Para um novo pagamento ou informações entre em contato com ${store}.`
    },
    11: {
        title: 'Pagamento não concluído.',
        subTitle: store => `Entre em contato com ${store} e solicite um novo link de pagamento.`
    },
    13: {
        title: 'Pagamento negado.',
        subTitle: store => `Entre em contato com ${store} e solicite um novo link de pagamento.`
    }
}
