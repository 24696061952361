import React from 'react'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Loading from "../../loading/loading";

const userInitial = {
    user: '',
    password: ''
}

function AuthenticationForm({submit, loading, error}) {

    const [userState, setUserState] = React.useState(userInitial)

    const handleUser = e => {
        setUserState({...userState, user: e.target.value})
    }

    const handlePassword = e => {
        setUserState({...userState, password: e.target.value})
    }

    const submitForm = e => {
        e.preventDefault()

        submit(userState)
    }

    return (
        <form onSubmit={submitForm}>
            <FormControl fullWidth required>
                <InputLabel htmlFor="user">Usuário</InputLabel>
                <Input id="user" onChange={handleUser} autoFocus={true}
                       value={userState.user} aria-describedby="user-helper-text"/>
            </FormControl>
            <br/><br/>
            <FormControl fullWidth required>
                <InputLabel htmlFor="password">Senha</InputLabel>
                <Input id="password" onChange={handlePassword} type="password"
                       value={userState.password} aria-describedby="password-helper-text"/>
            </FormControl>
            <br/><br/>
            {
                error &&
                    <div align="center">
                        <label style={{color: 'red'}}>{error}</label>
                        <br/><br/>
                    </div>

            }
            <div align="center" style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                <Button disabled={loading} type="submit" color={"primary"}
                        variant={"contained"}>
                    Autenticar
                </Button>
                {
                    loading && <Loading size="1.3rem"/>
                }
            </div>
        </form>
    )
}

export default AuthenticationForm
