const defaultMaskCard = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

const documents = {
    cpf: [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/],
    cnpj: [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]
}

const createMask = (lengths, gaps) => {

    if (!lengths || !gaps) {
        return defaultMaskCard
    }

    const max = lengths[lengths.length - 1]
    let count = 0
    let mask = []

    for (let i = 0; i < max; i++) {
        if (count === gaps.length) {
            mask = mask.concat(/[0-9]/)
        } else {
            if (i === gaps[count]) {
                mask = mask.concat(' ')
                count++
            }

            mask = mask.concat(/[0-9]/)
        }
    }
    return mask
}

export const masks = {
    documents,
    createMask
}
