import './previewSlip.scss'

import React from 'react'
import Barcode from 'react-barcode'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import CheckIcon from '@material-ui/icons/Check'

const regexJustNumbers = /(\d+)([.\s]*)/g
const replaceJustNumbersValue = `$1`

const barCodeOptions = {
    width: 2,
    height: 100,
    format: "CODE128",
    displayValue: false,
    fontOptions: "",
    font: "Roboto",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: 16,
    background: "none",
    lineColor: "#000000",
    margin: 10,
    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined
}


function PreviewSlip({barCode = "", urlBankSlip= ""}) {

    const [showAlert, setShowAlert] = React.useState(false)

    React.useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false)
            }, 5000);
        }
    }, [showAlert])

    function copyToClipboard() {
        const textArea = document.createElement('textArea')
        textArea.innerText = barCode.replace(regexJustNumbers, replaceJustNumbersValue)
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        textArea.remove()
        setShowAlert(true)
    }

    function goToUrl() {
        window.open(urlBankSlip, '_top')
    }

    function getOriginalBarcode() {
        const justNumbers = barCode.replace(regexJustNumbers, replaceJustNumbersValue)
        if (justNumbers.length === 48) {
            const regex = /(\s\d\s)/gm;
            const subst = ``;
            return barCode.concat(' ').replace(regex, subst)
        }

        if (justNumbers.length === 47) {
            const regex = /(\d{4})(\d{5})(\d)(\d{10})(\d)(\d{10})(\d)(\d)(\d{14})/gm;
            const subst = `$1$8$9$2$4$6`;
            return justNumbers.concat(' ').replace(regex, subst)
        }
    }

    return (
        <div className="preview-slip">
            <Grid container justify="space-between" alignItems="center">
                <Grid xs={12}>
                    <h2>Escaneie ou copie o código para efetuar o pagamento.</h2>
                </Grid>
                <Grid xs={12} className="barcode">
                    <Barcode value={getOriginalBarcode()} {...barCodeOptions}/>
                </Grid>
                <Grid xs={12}>
                    <h4 style={{marginTop: 0}}>{barCode}</h4>
                </Grid>
                <Grid xs={12}>
                    <div>
                        <Button onClick={copyToClipboard} color="primary">
                            <FileCopyOutlinedIcon style={{marginRight: 8}}/> Copiar
                        </Button>
                        ou
                        <Button onClick={goToUrl} color="primary">
                            <OpenInNewIcon style={{marginRight: 8}}/> Ver boleto
                        </Button>
                    </div>
                </Grid>
                {
                    showAlert &&
                    <Grid xs>
                        <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success">
                            Código copiado para área de transferência!
                        </Alert>
                        <br/>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

export default PreviewSlip
