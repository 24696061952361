export const containError = (input, props) => {
    let value = input.target ? input.target.value : input

    if (props.required) {
        if (!value.length) {
            return "required"
        }
    }

    if (!value.length){
        return false
    }

    if (props.min) {
        if (value.trim().length < props.min){
            return "min"
        }
    }

    if (props.max) {
        if (value.length > props.max) {
            return "max"
        }
    }

    if (props.pattern) {
        if (!props.pattern.test(value.toLowerCase())) {
            return "pattern"
        }
    }

    if (props.contain) {
        if (value.indexOf(props.contain) === -1) {
            return "contain"
        }
    }

    if (props.customs) {
        const found = props.customs.find(custom => custom(value))
        if (found) {
            return found(value)
        }
    }

    if (input.target) {
        input.focus = true
    }

    return false
}
