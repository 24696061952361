function loadLibraryPaygoGateway() {
    return new Promise((resolve, reject) => {
        const paygoGateway = document.createElement('script');
        paygoGateway.type = 'text/javascript';
        paygoGateway.async = true;
        paygoGateway.src = process.env.REACT_APP_PAYGO_GATEWAY_CARDHASH_URL;
        paygoGateway.onload = () => resolve();
        paygoGateway.onerror = () => reject();
        document.body.appendChild(paygoGateway);
    });
}

function libraryIsLoaded() {
    return typeof window.paygo != 'undefined' ? Promise.resolve() : Promise.reject();
}

function getConfiguration() {
    return {
        Authorization: process.env.REACT_APP_PAYGO_GATEWAY_CARDHASH_AUTHORIZATION,
        Environment: process.env.REACT_APP_PAYGO_GATEWAY_CARDHASH_ENVIRONMENT
    };
}

function getOptions() {
    return {
        ttl: process.env.REACT_APP_PAYGO_GATEWAY_CARDHASH_TTL
    };
}

function configureEncryptionKey(encryptionKey) {
    return libraryIsLoaded().then(() => window.paygo.gateway.configureEncryptionKey({
        encryptionKey: encryptionKey
    }));
}

function generateEncryptionKey(callbackSetEncryptionKey) {
    return libraryIsLoaded().then(() => window.paygo.gateway.configure(getConfiguration()))
        .then(() => window.paygo.gateway.configureOptions(getOptions()))
        .then(() => window.paygo.gateway.generateEncryptionKey())
        .then(encryptionKey => callbackSetEncryptionKey(encryptionKey));
}

function generateCardHash(cardInfo) {
    return libraryIsLoaded().then(() => window.paygo.gateway.generateCardHash(cardInfo));
}

export const paygoCheckoutUtil = {
    loadLibraryPaygoGateway,
    configureEncryptionKey,
    generateEncryptionKey,
    generateCardHash
}
