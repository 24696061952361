import React from 'react'
import MaskedInput from "react-text-mask";
import {masks} from "../../utils/mask.util";

function CardNumberInput(props) {
    const { inputRef, gaps, lengths, ...other } = props;
    const mask = masks.createMask(lengths, gaps)

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            showMask
            guide={false}
        />
    );
}

CardNumberInput.defaultProps = {

}

export default CardNumberInput
