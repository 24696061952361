import {makeStyles} from "@material-ui/styles";

export const useStyles = theme => makeStyles({
    root: {
      marginTop: '118px'
    },
    img: {
        width: "80px",
        height: "auto",
        marginLeft: "5px"
    },
    h4: {
        fontWeight: "normal"
    },
    a: {
        '&:visited': {
            color: theme.palette.text.hint
        }
    },
    p: {
        fontSize: '0.8rem',
        letterSpacing: '0.32px'
    }
})
