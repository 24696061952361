import {http} from "../utils/http.util";
import {axiosConfigs} from "../utils/axios-configs.util";

const httpApiService = http.httpApiService

const save = (theme, token) => {
    const config = axiosConfigs.withBearer(token)

    return httpApiService.post(`/checkout/configure`, theme, config).then(res => {
        return res
    }).catch(err => {
        throw err
    })
}

const getUrlTheme = token => {
    const config = axiosConfigs.withBearer(token)

    return httpApiService.get(`/checkout/url-style`, config).then(res => {
        return res
    }).catch(err => {
        throw err
    })
}

const getTheme = url => {
    return http.axios.get(url).then(res => {
        return res.data
    }).catch(err => {
        throw err
    })
}

export const themeService = {
    save,
    getUrlTheme,
    getTheme
}
