import {makeStyles} from "@material-ui/styles";

export const useStyles = theme => makeStyles({
    valueToPay: {
        color: theme.palette.text.primary
    },
    h3: {
        color: theme.palette.text.primary
    }
})
