import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Intention from './pages/intention/intention';
import MainLayout from './layouts/mainLayout';
import {ThemeProvider} from '@material-ui/styles';
import dotenv from 'dotenv'
import {themes} from './themes/themes';
import IntentionCustomize from './pages/intention-customize/intention-customize';
import {intentionMock} from './mocks/intention.mock';
import {customTheme} from './themes/custom-theme';
import ThreeDSPage from './pages/3ds/3ds.page';
import SaveCard from './pages/save-card/save-card';
import ValidateCard from './pages/validate-card/validate-card';

dotenv.config()

function App() {

    const themeSelected = process.env.REACT_APP_THEME
    const [theme, setTheme] = React.useState(themes[themeSelected].theme)

    React.useEffect(() => {
        if (themeSelected === 'c6pay') {
            document.title = 'Checkout C6Pay'
        }
    }, [themeSelected])

    const setThemeCustom = options => {
        setTheme(customTheme(options))
    }

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <MainLayout>
                    <Router>
                        <div>
                            <Switch>
                                <Route path="/customize"
                                       render={(props) => <IntentionCustomize intention={intentionMock}
                                                                              setTheme={setThemeCustom}  {...props} />}/>
                                <Route path="/payment/:token"
                                       render={(props) => <Intention setTheme={setThemeCustom} {...props}/>}/>
                                <Route path="/save-card/:token"
                                       render={(props) => <SaveCard setTheme={setThemeCustom} {...props}/>}/>
                                <Route path="/validate-card/:token"
                                       render={(props) => <ValidateCard setTheme={setThemeCustom} {...props}/>}/>
                                <Route path="/3ds/:acquirer/:transactionId"
                                       render={(props) => <ThreeDSPage setTheme={setThemeCustom} {...props}/>}/>
                                <Route path="*">
                                    <Redirect to="/payment/not-found"/>
                                </Route>
                            </Switch>
                        </div>
                    </Router>
                </MainLayout>
            </ThemeProvider>
        </div>
    );
}

export default App;
