import {oldPayGo} from "./old-paygo";
import {newPayGo} from "./new-paygo";
import {c6pay} from "./c6pay";
import {solpay} from "./solpay";

export const themes = {
    "old-paygo": oldPayGo,
    "new-paygo": newPayGo,
    "c6pay": c6pay,
    "solpay": solpay,
}
