import React from 'react';

function GetnetForm({transaction}) {

    React.useEffect(() => {
        if (transaction) {
            setTimeout(() => {
                document.getElementById('bt-submit').click();
            }, 2000)
        }
    }, [transaction])

    return (
        <form action={transaction.redirectUrl} method='POST' style={{display: 'none'}}>
            <input value={transaction.issuerPaymentId} name='MD' />
            <input value={transaction.payerAuthenticationRequest} name='PaReq' />
            <input value={transaction.termUrl} name='TermUrl' />
            <button type='submit' id='bt-submit'>Enviar</button>
        </form>
    )
}

export default GetnetForm
