const getDateHoursFromExpiry = expiry => {
    if (!expiry) {
        return undefined
    }
    const date = new Date(expiry)
    return date.toTimeString().split(' ')[0]
}

const getDateFromExpiry = expiry => {
    if (!expiry) {
        return undefined
    }

    const date = new Date(expiry)
    let day = date.getDate().toString()
    day = day.length === 1 ? `0${day}` : day
    let month = date.getMonth() + 1
    month = month <= 9 ? `0${month}` : month
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
}

export const dateUtil = {
    getDateFromExpiry,
    getDateHoursFromExpiry
}
