import React from 'react';
import {useStyles} from '../intention/intention.style';
import {checkoutService} from '../../services/checkout.service';
import Grid from '@material-ui/core/Grid';
import {withTheme} from '@material-ui/styles';
import {Acquirer, getAcquirerFromName} from '../../services/acquirer.service';
import Loading from '../../components/loading/loading';

const acquirerService = new Acquirer()

function ThreeDSPage(props) {

    const theme = props.theme
    const classes = useStyles(theme)()
    const transactionId = props.match.params.transactionId
    const acquirerName = props.match.params.acquirer

    acquirerService.setAcquirer(getAcquirerFromName(acquirerName))

    const [accessToken, setAccessToken] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [transaction, setTransaction] = React.useState(null)

    React.useEffect(() => {
        if (transactionId) {
            checkoutService.authorizeToken3ds(transactionId).then(data => {
                setAccessToken(data.access_token)
                acquirerService.getTransaction(transactionId, data.access_token).then(transaction => {
                    setTransaction(transaction);
                }).catch(err => {
                    console.error(err)
                    setError('Houve um erro ao buscar as informações sobre o pagamento, tente novamente mais tarde.')
                })
            }).catch(err => {
                console.error(err)
                setError('Transação não encontrada.')
            })
        }
    }, []);

    if (!error && !accessToken) {
        return (
            <div>
                <Loading fullWidth/>
            </div>
        )
    }

    return (
        <div className={classes}>
            <Grid container spacing={5} justify={'center'} alignItems={'center'} style={{minHeight: '60vh'}}>
                <Grid item>
                    {
                        error &&
                        <h1 className={classes.h1}>{error}</h1>
                    }
                    {
                        !error &&
                        <div align='center'>
                            <h1 className={classes.h1}>Estamos finalizando seu pagamento, aguarde!</h1>
                            <p>Em instantes você será redirecionado para o banco emissor do seu cartão.</p>
                        </div>
                    }
                </Grid>
            </Grid>
            {
                transaction && acquirerService.getFormTransaction(transaction)
            }
        </div>
    )
}

export default withTheme(ThreeDSPage)
