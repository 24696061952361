import './../../custom.scss'
import './save-card.scss'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import jwt from 'jsonwebtoken'
import { useStyles } from './save-card.style'
import Terms from '../../components/terms/terms'
import { withTheme } from '@material-ui/styles'
import IdentificationForm from '../../components/forms/identification/identificationForm'
import TokenizationForm from '../../components/forms/tokenization/tokenizationForm'
import { brandsAvailable } from '../../utils/brands-availables'
import { checkoutService } from '../../services/checkout.service'
import { tokenizationIntentErrors } from '../../utils/tokenization-intent-errors.util'
import { gate2allService } from '../../services/gate2all.service'
import { brandsUtils } from '../../utils/brands-api.util'
import { tokenizationStatus } from '../../utils/tokenization-status.util'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { themeService } from '../../services/theme.service'
import Loading from '../../components/loading/loading'
import ConfirmDialog from '../../components/confirmDialog/confirmDialog';
import { paygoCheckoutUtil } from '../../utils/paygo-checkout.utils';
import StepperWrapped from '../../components/stepper/stepperWrapped'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

function getSteps() {
    return ['Identificação', 'Cartão']
}

function SaveCard(props) {

    const theme = props.theme
    const classes = useStyles(theme)()

    const tokenizationId = props.match.params.token

    const [intention, setIntention] = React.useState(null)
    const [accessToken, setAccessToken] = React.useState(null)
    const [error, setError] = React.useState(false)
    const [errorStatus, setErrorStatus] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [loadingTokenization, setLoadingTokenization] = React.useState(false)
    const [tokenizationResult, setTokenizationResult] = React.useState(null)
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [infoSnackbar, setInfoSnackbar] = React.useState('')
    const [errorTokenization, setErrorTokenization] = React.useState('')
    const [loadedTheme, setLoadedTheme] = React.useState(false)
    const [confirmRenewSession, setConfirmRenewSession] = React.useState(false)
    const [idTimeoutDialog, setIdTimeoutDialog] = React.useState(0)
    const [idTimeoutAutoCancel, setIdTimeoutAutoCancel] = React.useState(0)
    const [encryptionKey, setEncryptionKey] = React.useState(null)
    const [activeStep, setActiveStep] = React.useState(0)
    const [steps, setSteps] = React.useState(getSteps())
    const [identification, setIdentification] = React.useState(null)
    const [loadingPayment, setLoadingPayment] = React.useState(false)

    const shouldRedirectToValidateCard = intentionResult => {
        return typeof intentionResult.validateCard !== 'undefined' && intentionResult.validateCard
    }

    // carrega a intenção de tokenização com o api-service
    React.useEffect(() => {
        if (tokenizationId) {
            // verifica se o token existe e gera uma autorização
            checkoutService.authorizeTokenization(tokenizationId).then(data => {
                // consulta os dados da intenção
                checkoutService.getIntentionTokenization(tokenizationId, data['access_token']).then(res => {
                    if (res.data && res.data.status === 0) {
                        setErrorConfig(0)
                    }

                    if (res.data && res.data.status === 2 && res.data.validateCard) {
                        setErrorConfig(1)

                        if (shouldRedirectToValidateCard(res.data)) {
                            const redirectUrl = `${process.env.REACT_APP_VALIDATE_CARD_URL}/${tokenizationId}`
                            setTimeout(() => {
                                setSnackbarOpen(true)
                                setInfoSnackbar(`Você está sendo redirecionado para: ${redirectUrl}`)
                            }, 1000)
                            setTimeout(() => {
                                window.open(redirectUrl, '_top')
                            }, 4000)
                        }
                    }

                    if (res.headers.authorization) {
                        const intentionResult = res.data

                        setIntention(intentionResult)
                        setAccessToken(res.headers.authorization)

                        paygoCheckoutUtil.loadLibraryPaygoGateway()
                            .then(() => paygoCheckoutUtil.generateEncryptionKey(setEncryptionKey))
                            .catch(err => console.error(err))

                        if (intentionResult.validateCard) {
                            setActiveStep(0)
                        } else {
                            setActiveStep(1)
                        }
                    }

                    setLoading(false)
                }).catch(err => {
                    setErrorConfig(-1)
                })
            }).catch(err => {
                setErrorConfig(-1)
            })
        }
    }, [tokenizationId])

    // verifica se existe tema para esse cliente, caso contrário mantém o default
    React.useEffect(() => {
        if (!loadedTheme && intention) {
            if (intention.urlStyleCheckout) {
                themeService.getTheme(intention.urlStyleCheckout).then(data => {
                    setLoadedTheme(true)
                    props.setTheme(data)
                }).catch(err => {
                    console.log(err)
                    setLoadedTheme(true)
                })
            } else {
                setLoadedTheme(true)
            }
        }
    }, [intention, loadedTheme])

    // requisita novo token e renova a chave de criptografia após confirmação do usuário
    const onConfirmRenewSession = () => {
        clearTimeout(idTimeoutAutoCancel)
        clearTimeout(idTimeoutDialog)
        setConfirmRenewSession(false)
        renewAccessToken(null)
        renewEncryptionKey()
    }

    // altera o tokenizationResult para não ser mais possível salvar o cartão
    const onCancelRenewSession = () => {
        clearTimeout(idTimeoutAutoCancel)
        clearTimeout(idTimeoutDialog)
        setConfirmRenewSession(false)
        setTokenizationResult({ status: -2 })
    }

    const setErrorConfig = (status) => {
        setError(true)
        setLoadedTheme(true)
        setErrorStatus(status)
        setLoading(false)
    }

    // cria o dialog de confirmação para renovar sessão
    React.useEffect(() => {
        if (!tokenizationResult && !error && accessToken) {
            const token = jwt.decode(accessToken)
            const myDate = new Date(token.exp * 1000);
            const today = new Date()
            const difference = myDate.getTime() - today.getTime()
            const threeMinutes = 60000 * 3
            const timeToOpen = difference - threeMinutes
            const idTimeoutDialog = setTimeout(() => {
                setConfirmRenewSession(true)
                const idAutoCancel = setTimeout(() => {
                    onCancelRenewSession()
                }, threeMinutes - 1000)
                setIdTimeoutAutoCancel(idAutoCancel)
            }, timeToOpen)
            setIdTimeoutDialog(idTimeoutDialog)
        }
    }, [accessToken, tokenizationResult, error])

    // mostra o loading se não tiver processado as informações
    if (loading || !loadedTheme) {
        return <Loading fullWidth />
    }

    // fecha a notificação
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false)
    }

    const isIntentionWithValidateCard = () => {
        return intention && typeof intention.validateCard != 'undefined' && intention.validateCard;
    }

    const createCustomerFromIdentification = () => {
        return {
            name: identification.name,
            document: identification.document.replace(/(\d*)([.\-/])/g, `$1`),
            email: identification.email,
            phoneNumber: identification.phone.replace(/(\d*)([ ()])/g, `$1`),
            address: {
                address: identification.address.address,
                number: identification.address.number,
                district: identification.address.neighborhood,
                zipcode: identification.cep.replace(/(\d*)([-])/g, `$1`),
                city: identification.address.city,
                state: identification.address.state,
                complement: identification.address.complement
            }
        }
    }

    // cria o objeto de tokenização que será enviado para o gate2all
    const createTokenization = card => {
        return Promise.resolve({
            userAgent: navigator.userAgent,
            tokenizationId: intention.tokenIntention,
            referenceId: intention.referenceId,
            postBackUrl: intention.postBackUrl,
            redirectUrl: intention.redirectUrl,
            validateCard: isIntentionWithValidateCard(),
            customer: isIntentionWithValidateCard() ? createCustomerFromIdentification() : null,
            cardInfo: {
                number: card.number,
                expirationMonth: card.expiry.substr(0, 2),
                expirationYear: new Date().getFullYear().toString().substr(0, 2)
                    .concat(card.expiry.substr(3, 2)),
                cvv: isIntentionWithValidateCard() ? card.cvv : null,
                brand: brandsUtils.apiBrandsByPass[card.brand],
                holderName: card.name
            }
        })
    }

    const generateCardHash = tokenization => {
        return paygoCheckoutUtil.configureEncryptionKey(encryptionKey)
            .then(() => paygoCheckoutUtil.generateCardHash(tokenization.cardInfo))
            .then(cardHash => {
                tokenization.cardInfo = {
                    cardHash: cardHash
                }
            })
            .catch(err => console.error(err))
            .then(() => tokenization)
    }

    // envia o cartão para ser tokenizado
    const submitCard = card => {
        setLoadingTokenization(true)
        setLoading(true)
        createTokenization(card)
            .then(tokenization => generateCardHash(tokenization))
            .then(tokenization => {
                sendTokenizationWithCallback(tokenization, data => {
                    updateTokenizationResult(data)
                })
            })
    }

    const getRedirectUrlFrom = intention => {
        return new Promise((resolve, reject) => {
            let redirectUrl
            if (shouldRedirectToValidateCard(intention)) {
                redirectUrl = `${process.env.REACT_APP_VALIDATE_CARD_URL}/${tokenizationId}`
            } else {
                redirectUrl = intention.redirectUrl
            }
            resolve(redirectUrl)
          });
    }

    const redirectUrlIsDefined = intention => {
        return (intention.redirectUrl != 'undefined' && intention.redirectUrl)
            || shouldRedirectToValidateCard(intention)
    }

    const sendTokenizationWithCallback = (tokenization, onFinish) => {
        setLoadingTokenization(true)
        setLoading(true)
        gate2allService.tokenization(tokenization, accessToken).then(data => {
            if (tokenizationResultTokenIsDefined(data) && redirectUrlIsDefined(intention)) {
                updateTokenizationResult(data)
                getRedirectUrlFrom(intention).then(redirectUrl => {
                    setTimeout(() => {
                        setSnackbarOpen(true)
                        setInfoSnackbar(`Você está sendo redirecionado para: ${redirectUrl}`)
                    }, 1000)
                    setTimeout(() => {
                        window.open(redirectUrl, '_top')
                    }, 4000)
                })
            } else {
                onFinish(data)
            }
        }).catch(error => {
            setLoadingTokenization(false)
            setLoading(false)
            setErrorTokenization('Não foi possível salvar o cartão, tente novamente mais tarde!')
            renewAccessToken(error)
            renewEncryptionKey()
        })
    }

    // requisita um novo accessToken
    const renewAccessToken = (err) => {
        checkoutService.authorizeTokenization(tokenizationId).then(data => {
            setAccessToken(data['access_token'])
            if (err && err.response) {
                if ((parseInt(err.response.status) / 100) === 5) {
                    setErrorTokenization('Problemas na comunicação com o servidor.')
                    setSnackbarOpen(true)
                } else {
                    if (err.response.data) {
                        setErrorTokenization(err.response.data.error.message)
                        setSnackbarOpen(true)
                    }
                }
            }
        }).catch(() => {
            setTokenizationResult({ status: -2 })
        })
    }

    // requisita uma nova chave de criptografia
    const renewEncryptionKey = () => {
        paygoCheckoutUtil.generateEncryptionKey(setEncryptionKey)
            .catch(err => console.error(err))
    }

    const tokenizationResultTokenIsDefined = tokenizationResult => {
        return tokenizationResult.cardInfo?.hasOwnProperty('token')
    }

    const defineStatusTokenizationResult = tokenizationResult => {
        if (tokenizationResultTokenIsDefined(tokenizationResult)) {
            tokenizationResult.status = 0
        } else {
            tokenizationResult.status = 1
        }
    }

    // define no estado o valor retornado da tokenização
    const updateTokenizationResult = tokenizationResult => {
        defineStatusTokenizationResult(tokenizationResult)
        setTokenizationResult(tokenizationResult)
        setLoadingTokenization(false)
        setLoading(false)
    }

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const submitIdentification = identificationData => {
        setIdentification(identificationData)
        handleNextStep()
    }

    const identificationValidationIsRequired = () => {
        return intention.validateCard
    }

    const getInputsRequired = () => {
        let inputs = []
        if (!identificationValidationIsRequired()) {
            return inputs
        }

        return ['name', 'document', 'email', 'phone', 'cep', 'address', 'city', 'number',
            'neighborhood', 'state']
    }

    const getContentByStep = step => {
        if (step === 0) {
            return (<IdentificationForm submit={submitIdentification} identification={identification}
                customer={intention.customer} loadingPayment={loadingPayment}
                inputsRequired={getInputsRequired()}
                isRequired={identificationValidationIsRequired()} />)
        } else if (step === 1) {
            return (<><TokenizationForm submit={submitCard} brands={brandsAvailableReturned}
                loading={loadingTokenization} validateCard={isIntentionWithValidateCard()} /><Terms /></>)
        } else {
            return null
        }
    }

    const brandsAvailableReturned = brandsAvailable(intention && intention.brand ?
        [{ name: intention.brand }] : brandsUtils.brandsAvailable)

    let title = null
    let subTitle = null
    if (tokenizationResult) {
        title = tokenizationStatus[tokenizationResult.status].title
        subTitle = tokenizationStatus[tokenizationResult.status].subTitle()
    } else {
        if (activeStep === 0) {
            title = error ? `${tokenizationIntentErrors[errorStatus].whatsHappen}` : `Dados de identificação`
            subTitle = `Informe os seus dados para dar seguimento com a tokenização do cartão`
        } else {
            title = error ? `${tokenizationIntentErrors[errorStatus].whatsHappen}` : `Dados do cartão`
            subTitle = `Para salvar o seu cartão, preencha o formulário abaixo`
        }
    }

    return (
        <div className={classes}>
            <ConfirmDialog onOk={onConfirmRenewSession} visible={confirmRenewSession}
                onCancel={onCancelRenewSession}
                title="Deseja renovar sua sessão?"
                contentText={`Sua sessão vai expirar às 
                           ${accessToken
                        ? new Date(jwt.decode(accessToken).exp * 1000).toLocaleTimeString()
                        : ''
                    }`} />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={errorTokenization ? 'error' : 'info'}>
                    {errorTokenization ? errorTokenization : infoSnackbar}
                </Alert>
            </Snackbar>
            <Grid container spacing={5} justify={'space-between'} className='intetion-content-custom'>
                <Grid item md={5} sm={6} xs={12}>
                    <div className="principal-description">
                        <h1 className={classes.h1}>{title}</h1>
                        { !error && <p>{subTitle}</p> }
                    </div>
                    {
                        <div>
                            {
                                isIntentionWithValidateCard() && !error && !tokenizationResult &&
                                <><StepperWrapped activeStep={activeStep} steps={steps} visible={!error} />
                                <br /></>
                            }
                            {
                                !error && !tokenizationResult &&
                                <div>
                                    {
                                        getContentByStep(activeStep)
                                    }
                                </div>
                            }
                        </div>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default withTheme(SaveCard)
