import './paymentMethod.scss'

import React from 'react'
import Grid from '@material-ui/core/Grid';
import ConfirmDialog from '../confirmDialog/confirmDialog';
import {paymentMethods} from '../../constants/paymentMethod';
import CardTile from '../cardTile/cardTile';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoPix from '../info-pix/infoPix';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {useStylesBootstrap} from '../../utils/useStylesBootstrap';
import {gtmService} from '../../utils/gtm-datalayers.util';

const paymentMethodsDefault = Object.keys(paymentMethods).map(payment => paymentMethods[payment].value)

function PaymentMethod({onSelected, methodsAvailable = paymentMethodsDefault}) {

    const [confirmDialog, setConfirmDialog] = React.useState(false)
    const [preSelected, setPreSelected] = React.useState(null)
    const [resultDialog, setResultDialog] = React.useState(false)
    const [showInfoPix, setInfoPix] = React.useState(false)

    const classes = useStylesBootstrap();

    React.useEffect(() => {
        if (methodsAvailable.length === 1) {
            onSelected(methodsAvailable[0])
        }
    }, [methodsAvailable])

    React.useEffect(() => {
        if (resultDialog) {
            onSelected(preSelected)
        }
    }, [resultDialog])

    const pushDataLayer = (type, option) => {
        if (type === paymentMethods.PIX.value) {
            gtmService.paymentOptionPix(option);
        } else if (type === paymentMethods.BANK_SLIP.value) {
            gtmService.paymentOptionBankslip(option);
        } else if (type === paymentMethods.BANK_TRANSFER.value) {
            gtmService.paymentOptionTransfer(option);
        } else {
            gtmService.paymentOptionCard(option);
        }
    }

    const onClick = type => {
        setPreSelected(type);
        setConfirmDialog(true);
        pushDataLayer(type, true);
    }

    return (
        <div className={'payment-method'}>
            <ConfirmDialog visible={confirmDialog}
                           title="Confirmar método de pagamento"
                           contentText="Você realmente deseja selecionar essa opção?"
                           okText="Sim"
                           cancelText="Não"
                           onCancel={() => {
                               setResultDialog(false)
                               setConfirmDialog(false)
                           }}
                           onOk={() => {
                               pushDataLayer(preSelected, false)
                               setResultDialog(true)
                               setConfirmDialog(false)
                           }}
            />
            <Grid container alignItems="stretch" direction={'row'} spacing={2}>
                <Grid item xs={12}>
                    <label>Selecione uma forma de pagamento</label>
                </Grid>
                <br/><br/>
                {
                    methodsAvailable.includes(paymentMethods.PIX.value) &&
                    <Grid item xs={12}>
                        <InfoPix onConfirm={() => {
                            pushDataLayer(paymentMethods.PIX.value, false)
                            onSelected(paymentMethods.PIX.value)
                        }} onClose={() => {
                            setInfoPix(false)
                        }} visible={showInfoPix}/>

                        <CardTile onClick={() => {
                            setPreSelected(paymentMethods.PIX.value)
                            pushDataLayer(paymentMethods.PIX.value, true)
                            setInfoPix(true)
                        }}
                                  description="O Pix é uma nova maneira de fazer transferências e pagamentos."
                                  icon={<img src={'/images/pix-icon.png'} alt='pix-icon' className='pix-icon'/>}
                                  value={paymentMethods.PIX.value} actualValue={preSelected}
                                  TitleComponent={() => {
                                      return (
                                          <div className='pix-info-title'>
                                              <h3>Pix</h3>
                                              <Tooltip arrow title="Clique e saiba mais" classes={classes}>
                                                  <IconButton id={'info-pix'}
                                                              onClick={() => {
                                                                  setPreSelected(paymentMethods.PIX.value)
                                                                  setInfoPix(true)
                                                              }}>
                                                      <InfoOutlinedIcon color="primary"/>
                                                  </IconButton>
                                              </Tooltip>
                                          </div>
                                      )
                                  }}/>
                    </Grid>
                }
                {
                    methodsAvailable.includes(paymentMethods.CARD.value) &&
                    <Grid item xs={12}>
                        <CardTile onClick={() => onClick(paymentMethods.CARD.value)} title="Cartão"
                                  description="Cartão de crédito, débito, ou débito virtual caixa."
                                  icon={<CreditCardIcon/>}
                                  value={paymentMethods.CARD.value} actualValue={preSelected}/>
                    </Grid>
                }
                {
                    methodsAvailable.includes(paymentMethods.BANK_SLIP.value) &&
                    <Grid item xs={12}>
                        <CardTile onClick={() => onClick(paymentMethods.BANK_SLIP.value)} title="Boleto"
                                  description="Copie o código de barras ou visualize o boleto."
                                  icon={<FileCopyOutlinedIcon/>}
                                  value={paymentMethods.BANK_SLIP.value}
                                  actualValue={preSelected}/>
                    </Grid>
                }
                {
                    methodsAvailable.includes(paymentMethods.BANK_TRANSFER.value) &&
                    <Grid item xs={12}>
                        <CardTile onClick={() => onClick(paymentMethods.BANK_TRANSFER.value)} title="Transferência"
                                  description="Realize a transferência com a confirmação do seu banco."
                                  group="payment-method" icon={<AttachMoneyOutlinedIcon/>}
                                  value={paymentMethods.BANK_TRANSFER.value} actualValue={preSelected}/>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

export default PaymentMethod
