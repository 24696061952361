import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";

const options = {
    palette: {
        primary: {
            main: "#FFD500"
        },
        secondary: {
            main: "#242424"
        },
        background: {
            default: "#F4F4F4",
            paper: "#FFFFFF"
        },
        text: {
            primary: "#242424",
            secondary: "#242424",
            hint: "#FFD500",
            disabled: "#CDCDCD"
        },

    },
    typography: {
        fontFamily: [
            'Montserrat',
            'Roboto',
            'sans-serif'
        ],
        h1: {
            fontStyle: "normal",
            fontWeight: "900",
            letterSpacing: "0.25px"
        }
    },
    mainLogo: '/images/PayGoVersaoPositiva.png',
    processLogo: '/images/PayGoVersaoPositiva.png',
}

const theme = responsiveFontSizes(createMuiTheme(options))

export const newPayGo = {
    theme,
    options
}
