import './payment.style.scss'
import React from 'react'
import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    withTheme
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {cnpj, cpf} from 'cpf-cnpj-validator';
import CardIcon from '../../cardIcon/cardIcon';
import DocumentInput from '../../inputs/documentInput';
import creditCardType, {getTypeInfo, types} from 'credit-card-type'
import CardNumberInput from '../../inputs/cardNumberInput';
import CardValidateInput from '../../inputs/cardValidateInput';
import {containError} from '../../../utils/input.validation.util';
import {cardTypes} from '../../../utils/cardType.util';
import {brandsUtils} from '../../../utils/brands-api.util';
import currencyFormatter from 'currency-formatter';
import Loading from '../../loading/loading';
import CardTile from '../../cardTile/cardTile';
import CreditCardIcon from '@material-ui/icons/CreditCard';

Object.keys(types).forEach(key => {
    creditCardType.removeCard(types[key])
})

const cardStateInitial = {
    brandIsSelected: false,
    number: '',
    cvv: '',
    name: '',
    expiry: '',
    document: '',
    installments: '',
    possibleCard: getTypeInfo(types.VISA)
}

let showInputAdornment = false;

function PaymentForm({submit, ...props}) {

    const loading = props.loading
    const intention = props.intention
    const brands = props.brands
    const [typeCard, setTypeCard] = React.useState(intention.payment.card.type === 0 ? 'credit' : cardTypes[intention.payment.card.type])
    const [card, setCard] = React.useState(cardStateInitial)
    const [loopFixInstallments, setLoopFixInstallments] = React.useState(true)
    let inputNumberRef = null

    React.useEffect(() => {
        brands.forEach(brand => {
            creditCardType.addCard(brandsUtils.brandsProps[brandsUtils.apiBrandsByPass[brand]].config)
        })
        setCard(prevState => {
            if (brands.includes(prevState.possibleCard.type)) {
                return {...prevState}
            }

            const brand = brands[0] ? brands[0] : 'visa'
            return {...prevState, possibleCard: getTypeInfo(brand)}
        })
    }, [brands])

    const possibleCard = card.possibleCard

    const inputsPropsValidation = {
        document: {
            props: {
                min: 14, max: 18, customs: [
                    value => {
                        const valueDocument = value.replace(/(\d*)([.\-/])/g, `$1`)
                        if (valueDocument.length === 11) {
                            if (!cpf.isValid(value)) {
                                return 'cpfNotValid'
                            }
                        }

                        if (valueDocument.length === 14) {
                            if (!cnpj.isValid(value)) {
                                return 'cnpjNotValid'
                            }
                        }
                    }
                ]
            },
            errors: {
                min: 'CPF/CNPJ deve conter no mínimo 14 caracteres!',
                max: 'CPF/CNPJ deve conter no máximo 18 caracteres!',
                cpfNotValid: 'CPF inválido',
                cnpjNotValid: 'CNPJ inválido',
            }
        },
        number: {
            props: {min: possibleCard ? possibleCard.lengths[0] : 0},
            errors: {
                min: `Cartão deve conter no mínimo ${possibleCard ? possibleCard.lengths[0] : 0} caracteres!`
            }
        },
        cvv: {
            props: {min: possibleCard ? possibleCard.code.size : 0, max: possibleCard ? possibleCard.code.size : 0},
            errors: {
                min: `${possibleCard ? possibleCard.code.name : 'CVV'} deve conter no mínimo ${possibleCard ? possibleCard.code.size : 0} caracteres!`,
                max: `${possibleCard ? possibleCard.code.name : 'CVV'} deve conter no máximo ${possibleCard ? possibleCard.code.size : 0} caracteres!`
            }
        },
        name: {
            props: {min: 3, max: 30},
            errors: {
                min: `Nome impresso no cartão deve conter no mínimo 3 caracteres!`,
                max: `Nome impresso no cartão deve conter no máximo 30 caracteres!`
            }
        },
        expiry: {
            props: {
                min: 5,
                customs: [
                    value => {
                        if (value.length === 5) {
                            const dates = value.split('/')
                            const month = parseInt(dates[0])

                            if (month < 1 || month > 12) {
                                return 'expiryMonthDate'
                            }

                            const today = new Date()
                            const year = parseInt(dates[1])
                            const yearActual = parseInt(today.getFullYear().toString().substr(2, 2))
                            if (year < yearActual) {
                                return 'expiryCard'
                            }

                            if (year === yearActual) {
                                const monthActual = today.getMonth() + 1
                                if (monthActual > month) {
                                    return 'expiryCard'
                                }
                            }
                        }

                        return false
                    }
                ]
            },
            errors: {
                min: 'Informe o mês e ano de validade do cartão. Ex.: MM/AA',
                expiryMonthDate: 'Por favor, insira um mês válido!',
                expiryCard: 'Cartão com validade expirada!'
            }
        }
    }

    const submitForm = e => {
        e.preventDefault()

        const dataResult = {
            card: {
                name: card.name,
                number: card.number,
                expiry: card.expiry,
                cvv: card.cvv,
                document: card.document,
                brand: card.possibleCard.type,
                cardType: typeCard,
                installments: card.installments
            }
        }
        submit(dataResult)
    }

    const onChangeCardNumber = e => {
        const result = e.target.value.replace(/([ ])/g, '')
        let possibleCard = card.possibleCard

        if (!card.brandIsSelected) {
            const brands = creditCardType(result)
            if (brands.length) {
                possibleCard = brands[0]
            }
        }

        showInputAdornment = result.length > 0;

        setCard({...card, possibleCard, number: result})
    }

    const onChangeValidate = e => {
        setCard({...card, expiry: e.target.value})
    }

    const onChangeCVV = e => {
        setCard({...card, cvv: e.target.value.replace(/([0-9]*)([a-zA-Z.+$,\][}#@!_=-Ç-()%/:;*ç'"])/g, `$1`)})
    }

    const onChangeName = e => {
        const result = e.target.value.replace(/([a-zA-Z]*)([0-9.+$,\][}#@!_=-Ç-()%/:;*'"]*)/g, `$1`).toUpperCase()
        setCard({...card, name: result})
    }

    const onChangeDocument = e => {
        setCard({...card, document: e})
    }

    const onChangeInstallments = e => {
        setCard({...card, installments: e.target.value})
    }

    const getInstallmentsOption = (intentionCard, amount) => {
        const installments = intentionCard.installments;
        const options = []

        for (let i = 1; i <= installments; i++) {
            const installmentValue = currencyFormatter.format((amount / i).toFixed(2), {
                locale: 'pt-BR',
                symbol: 'R$'
            });
            options.push(<MenuItem key={i} value={i}>{`${i}x de ${installmentValue}`}</MenuItem>);
        }

        if (loopFixInstallments && typeCard === 'credit' && isFixedInstallments()) {
            setCard({...card, installments: installments });
            setLoopFixInstallments(false);
        }

        if (loopFixInstallments && typeCard === 'debit') {
            setCard({...card, installments: 1 });
            setLoopFixInstallments(false);
        }

        return options
    }

    const isFixedInstallments = () => {
        return intention.payment.card.fixedInstallments || intention.payment.card.installments === 1;
    }

    const onChangeTypeCard = type => {
        if (inputNumberRef) {
            inputNumberRef.value = ''
            inputNumberRef.focus()
        }

        showInputAdornment = false;

        if (type === 'debit_elo_caixa') {
            showInputAdornment = true;
            setCard({...card,
                number: '',
                installments: 1,
                possibleCard: getTypeInfo('elo-caixa'),
                brandIsSelected: true
            });
        } else if (type === 'credit') {
            let updateToInstallments = '';

            if (isFixedInstallments()) {
                updateToInstallments = intention.payment.card.installments;
            }

            setCard({...card,
                possibleCard: getTypeInfo(brands.filter(b => b !== 'elo-caixa')[0]),
                brandIsSelected: false,
                installments: updateToInstallments,
                number: ''
            });
        } else {
            setCard({...card,
                possibleCard: getTypeInfo(brands.filter(b => b !== 'elo-caixa')[0]),
                brandIsSelected: false,
                installments: 1,
                number: ''
            });
        }

        setTypeCard(type);
    }

    const errorDocument = inputsPropsValidation.document.errors[containError(card.document, inputsPropsValidation.document.props)]
    const errorExpiry = inputsPropsValidation.expiry.errors[containError(card.expiry, inputsPropsValidation.expiry.props)]
    const errorNumber = inputsPropsValidation.number.errors[containError(card.number, inputsPropsValidation.number.props)]
    const errorCVV = inputsPropsValidation.cvv.errors[containError(card.cvv, inputsPropsValidation.cvv.props)]
    const errorName = inputsPropsValidation.name.errors[containError(card.name, inputsPropsValidation.name.props)]

    const canShowCreditTile = () => {
        return intention.payment.card.type === 0 || intention.payment.card.type === 1
    }

    const canShowDebitTile = () => {
        return intention.payment.card.type === 0 || intention.payment.card.type === 2
    }

    const canShowEloCaixaTile = () => {
        return brands.includes('elo-caixa') && (intention.payment.card.type === 0 || intention.payment.card.type === 2)
    }

    return (
        <form onSubmit={submitForm}>
            <FormControl fullWidth>
                <FormLabel component="legend">Tipo do cartão</FormLabel>
                <br/>
                <Grid container alignItems="stretch" direction={'row'} spacing={2}>
                    {
                        canShowDebitTile() &&
                        <Grid item xs={12}>
                            <CardTile onClick={() => onChangeTypeCard('debit')} value={'debit'} actualValue={typeCard}
                                      icon={<CreditCardIcon/>}
                                      title="Cartão de débito"
                                      description="Pagamento à vista."/>
                        </Grid>
                    }
                    {
                        canShowCreditTile() &&
                        <Grid item xs={12}>
                            <CardTile onClick={() => onChangeTypeCard('credit')} value={'credit'} actualValue={typeCard}
                                      icon={<CreditCardIcon/>}
                                      title="Cartão de crédito"
                                      description={`À vista ou parcelado em até ${intention.payment.card.installments}x`}/>
                        </Grid>
                    }
                    {
                        canShowEloCaixaTile() &&
                        <Grid item xs={12}>
                            <CardTile onClick={() => onChangeTypeCard('debit_elo_caixa')} value={'debit_elo_caixa'}
                                      actualValue={typeCard} icon={<CardIcon icon="elo-caixa"/>}
                                      title="Débito virtual Caixa"
                                      description="Pague com seu auxílio emergencial."/>
                        </Grid>
                    }
                </Grid>
            </FormControl>
            <br/><br/>
            <FormControl fullWidth required error={errorNumber}>
                <InputLabel htmlFor="number-card">Número do cartão</InputLabel>
                <Input id="number-card" autoComplete="cc-number"
                       inputRef={ref => inputNumberRef = ref}
                       autoFocus={true}
                       gaps={card.possibleCard.gaps}
                       lengths={card.possibleCard.lengths}
                       aria-describedby="number-helper-text"
                       onChange={onChangeCardNumber}
                       endAdornment={
                           showInputAdornment && <InputAdornment position={'end'} className="preview-card-icon" >
                                <CardIcon icon={ card.possibleCard.type } />
                           </InputAdornment>
                       }
                       inputComponent={CardNumberInput}
                       inputProps={{gaps: card.possibleCard.gaps, lengths: card.possibleCard.lengths}}/>
                <FormHelperText id="number-helper-text" error={errorNumber}>{errorNumber}</FormHelperText>
            </FormControl>
            <br/><br/>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <FormControl fullWidth required error={errorExpiry}>
                        <InputLabel htmlFor="validate-card">Validade</InputLabel>
                        <Input id="validate-card" autoComplete="cc-exp" inputComponent={CardValidateInput}
                               value={card.expiry} onChange={onChangeValidate}
                               aria-describedby={'expiry-helper-text'}/>
                        <FormHelperText id="expiry-helper-text" error={errorExpiry}>
                            {errorExpiry ? errorExpiry : 'MM/AA'}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth required error={errorCVV}>
                        <InputLabel htmlFor="cvv-card">{card.possibleCard.code.name}</InputLabel>
                        <Input id="cvv-card" autoComplete="cc-csc"
                               value={card.cvv}
                               onChange={onChangeCVV}
                               aria-describedby={'cvv-helper-text'}
                               endAdornment={
                                   <InputAdornment position={'end'}>
                                       <Tooltip
                                           title="Os três ou quatro números que geralmente ficam no verso do seu cartão."
                                           placement="top">
                                           <InfoOutlinedIcon color={'primary'}/>
                                       </Tooltip>
                                   </InputAdornment>
                               }/>
                        <FormHelperText id={'cvv-helper-text'} error={errorCVV}>{errorCVV}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <br/>
            <FormControl fullWidth required error={errorName}>
                <InputLabel htmlFor="name-card">Nome</InputLabel>
                <Input id="name-card" autoComplete="cc-name" value={card.name} onChange={onChangeName}/>
                <FormHelperText id="name-helper-text" error={errorName}>{
                    `${errorName ? errorName : 'Insira o nome como está escrito no cartão.'}`
                }</FormHelperText>
            </FormControl>
            <br/><br/>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <FormControl fullWidth error={errorDocument}>
                        <InputLabel htmlFor="document-card">CPF/CNPJ do dono do cartão</InputLabel>
                        <Input id="document-card" inputComponent={DocumentInput} value={card.document}
                               onChange={onChangeDocument} aria-describedby={'document-helper-text'}/>
                        <FormHelperText id="document-helper-text" error={errorDocument}>{errorDocument}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth required disabled={isFixedInstallments() || typeCard !== 'credit'}>
                        <InputLabel htmlFor="installments">Parcelas</InputLabel>
                        <Select labelId="installments" value={card.installments}
                                onChange={onChangeInstallments}
                                id="installments"
                                inputProps={{ disabled: isFixedInstallments() || typeCard !== 'credit' }}>
                            <MenuItem value="">
                                <em>Selecione</em>
                            </MenuItem>
                            {
                                getInstallmentsOption(intention.payment.card, intention.formattedAmount)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                {
                    props.cancelPayment != null &&
                    <Grid item xs={'auto'}>
                        <Button disabled={loading} type="button" variant={'contained'}
                                onClick={props.cancelPayment}>Voltar</Button>
                    </Grid>
                }
                <Grid item xs={'auto'}>
                    <div align="center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Button
                            disabled={loading || errorName || errorCVV || errorNumber || errorExpiry || errorDocument}
                            type="submit"
                            color={'primary'} variant={'contained'}>
                            Pagar
                        </Button>
                        {
                            loading && <Loading size="1.3rem"/>
                        }
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

export default withTheme(PaymentForm)
