import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {InputAdornment} from '@material-ui/core';
import {cpf, cnpj} from 'cpf-cnpj-validator';
import {statesBrazil} from '../../../utils/states-brazil.util';
import {makeStyles, withTheme} from '@material-ui/styles';
import {viaCepService} from '../../../utils/viaCep.utils';
import CepInput from '../../inputs/cepInput';
import DocumentInput from '../../inputs/documentInput';
import PhoneInput from '../../inputs/phoneInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import {containError} from '../../../utils/input.validation.util';
import Loading from '../../loading/loading';
import {gtmService} from '../../../utils/gtm-datalayers.util';

const sizeCircularProgress = '1.3rem'

const useStyle = theme => makeStyles({
    a: {
        '&:visited': {
            color: theme.palette.text.hint
        }
    }
})

const identificationStateInitial = {
    document: '',
    email: '',
    phone: '',
    cep: '',
    name: ''
}

const addressStateInitial = {
    address: '',
    city: '',
    number: '',
    neighborhood: '',
    state: '',
    complement: ''
}

const inputsPropsValidation = {
    name: {
        props: {
            min: 7,
            pattern: /^(?![\s-]*\d[\d\s-]*$).+/
        },
        errors: {
            min: 'O nome deve ter pelo menos 7 caracteres!',
            pattern: 'O nome digitado não é válido ou está incompleto!'
        }
    },
    document: {
        props: {
            min: 14, max: 18, customs: [
                value => {
                    const valueDocument = value.replace(/(\d*)([.\-/])/g, `$1`)
                    if (valueDocument.length === 11) {
                        if (!cpf.isValid(value)) {
                            return 'cpfNotValid'
                        }
                    }

                    if (valueDocument.length === 14) {
                        if (!cnpj.isValid(value)) {
                            return 'cnpjNotValid'
                        }
                    }
                }
            ]
        },
        errors: {
            min: 'CPF/CNPJ deve conter no mínimo 14 caracteres!',
            max: 'CPF/CNPJ deve conter no máximo 18 caracteres!',
            cpfNotValid: 'O CPF digitado não é valido!',
            cnpjNotValid: 'O CNPJ digitado não é valido!'
        }
    },
    email: {
        props: {
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        errors: {
            pattern: 'Valor digitado não é um e-mail válido!'
        }
    },
    phone: {
        props: {min: 16},
        errors: {
            min: 'Valor digitado não corresponde ao padrão do exemplo. Ex.: (99) 9 9999 9999'
        }
    },
    cep: {
        props: {min: 9},
        errors: {
            min: 'CEP deve conter pelo menos 9 caracteres. Ex.: 99999-999'
        }
    }
}


function IdentificationForm({submit, isRequired, customer, paymentMethodSelected = 'card', inputsRequired, loadingPayment = false, ...props}) {

    const [loading, setLoading] = React.useState(false)
    const [addressState, setAddress] = React.useState(addressStateInitial)
    const [identificationState, setIdentification] = React.useState(identificationStateInitial)
    const [submitFormFast, setSubmitFormFast] = React.useState(false)
    const [hasErrorState, setHasErrorState] = React.useState(false)

    React.useEffect(() => {
        if (props.identification) {
            setAddress(props.identification.address)
            setIdentification(props.identification)
        }
    }, [props.identification])

    React.useEffect(() => {
        if (submitFormFast && addressState && identificationState) {
            submitForm(null)
        }
    }, [addressState, identificationState, submitFormFast])

    React.useEffect(() => {
        if (customer) {
            const identification = {
                document: customer.document ? (customer.document.length === 11 ? customer.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, `$1.$2.$3-$4`)
                    : customer.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, `$1.$2.$3/$4-$5`)) : '',
                email: customer.email ? customer.email : '',
                phone: customer.phoneNumber ? customer.phoneNumber.replace(/(\d{2})(\d)(\d{4})(\d{4})/g, `($1) $2 $3 $4`) : '',
                cep: (customer.address && customer.address.zipcode) ? customer.address.zipcode.replace(/(\d{5})(\d{3})/g, `$1-$2`) : '',
                name: customer.name ? customer.name : ''
            }
            const address = {
                address: customer.address.address ? customer.address.address : '',
                city: customer.address.city ? customer.address.city : '',
                number: customer.address.number ? customer.address.number : '',
                neighborhood: customer.address.district ? customer.address.district : '',
                state: customer.address.state ? customer.address.state : '',
                complement: customer.address.complement ? customer.address.complement : '',
            }
            setAddress(address)
            setIdentification(identification)

            if (!isRequired) {
                setSubmitFormFast(true)
            }
        }
    }, [customer])

    React.useEffect(() => {
        if (identificationState.cep.length === 9 && isRequired && inputsRequired.includes('cep') && !addressState.state.length) {
            setHasErrorState(true)
        } else {
            setHasErrorState(false)
        }
    }, [isRequired, inputsRequired, addressState.state, identificationState.cep])

    const theme = props.theme
    const classes = useStyle(theme)()

    const onChangeCep = e => {
        const result = e.target.value
        const cepNoMask = result.replace(/([-])/g, '').trim()
        if (cepNoMask.length === 8) {
            setLoading(true)
            viaCepService.getAddress(cepNoMask).then(res => {
                if (res.erro) {
                    setAddress({
                        ...addressState,
                        neighborhood: '',
                        city: '',
                        address: '',
                        state: ''
                    })
                } else {
                    setAddress({
                        ...addressState,
                        neighborhood: res.bairro ? res.bairro : '',
                        city: res.localidade ? res.localidade : '',
                        address: res.logradouro ? res.logradouro : '',
                        state: res.uf ? res.uf : ''
                    })
                }
                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setAddress({
                    ...addressState,
                    neighborhood: '',
                    city: '',
                    address: '',
                    state: ''
                })
            })
        }
        setIdentification({...identificationState, cep: result})
    }

    const onChangeDocument = e => {
        setIdentification({...identificationState, document: e})
    }

    const onChangeName = e => {
        setIdentification({...identificationState, name: e.target.value})
    }

    const onChangeEmail = e => {
        setIdentification({...identificationState, email: e.target.value})
    }

    const onChangePhone = e => {
        setIdentification({...identificationState, phone: e.target.value})
    }

    const onChangeAddress = e => {
        setAddress({...addressState, address: e.target.value})
    }

    const onChangeNumber = e => {
        const value = e.target.value.toUpperCase()
        if (
            (value.length === 1 && value.startsWith('S')) ||
            (value.length === 2 && value.startsWith('S/')) ||
            (value.length === 3 && value.startsWith('S/N'))
        ) {
            setAddress({...addressState, number: value})
        } else {
            const regex = /(\d+\s*([A-Z](?![A-Z]))?)/g;
            const match = regex.exec(value)
            if (match && match.length) {
                setAddress({...addressState, number: match[0]})
            }
        }
    }

    const onChangeNeighborhood = e => {
        setAddress({...addressState, neighborhood: e.target.value})
    }

    const onChangeCity = e => {
        setAddress({...addressState, city: e.target.value})
    }

    const onChangeState = e => {
        setAddress({...addressState, state: e.target.value})
    }

    const onChangeComplement = e => {
        setAddress({...addressState, complement: e.target.value})
    }

    const submitForm = e => {
        if (e) {
            e.preventDefault()
        }

        if (paymentMethodSelected === 'bankSlip') {
            gtmService.getBankslip();
        }

        const dataResult = {
            address: addressState,
            document: identificationState.document,
            email: identificationState.email,
            phone: identificationState.phone,
            cep: identificationState.cep,
            name: identificationState.name
        }
        submit(dataResult)
    }

    const errorName = inputsPropsValidation.name.errors[containError(identificationState.name, inputsPropsValidation.name.props)]
    const errorDocument = inputsPropsValidation.document.errors[containError(identificationState.document, inputsPropsValidation.document.props)]
    const errorEmail = inputsPropsValidation.email.errors[containError(identificationState.email, inputsPropsValidation.email.props)]
    const errorPhone = inputsPropsValidation.phone.errors[containError(identificationState.phone, inputsPropsValidation.phone.props)]
    const errorCEP = inputsPropsValidation.cep.errors[containError(identificationState.cep, inputsPropsValidation.cep.props)]

    return (
        <form onSubmit={submitForm}>
            <FormControl fullWidth required={isRequired && inputsRequired.includes('name')} error={errorName}>
                <InputLabel htmlFor="name">Nome completo</InputLabel>
                <Input id="name" onChange={onChangeName} autoFocus={true} disabled={customer && customer.name}
                       value={identificationState.name} aria-describedby="name-helper-text"/>
                <FormHelperText error={errorName} id="name-helper-text">{errorName}</FormHelperText>
            </FormControl>
            <br/><br/>
            <FormControl fullWidth required={isRequired && inputsRequired.includes('document')} error={errorDocument}>
                <InputLabel htmlFor="document">CPF/CNPJ</InputLabel>
                <Input id="document" inputComponent={DocumentInput} onChange={onChangeDocument}
                       disabled={customer && customer.document}
                       value={identificationState.document} aria-describedby="document-helper-text"/>
                <FormHelperText error={errorDocument} id="document-helper-text">{errorDocument}</FormHelperText>
            </FormControl>
            <br/><br/>
            <FormControl fullWidth required={isRequired && inputsRequired.includes('email')} error={errorEmail}>
                <InputLabel htmlFor="email">E-mail</InputLabel>
                <Input type="email" id="email" aria-describedby="email-helper-text" value={identificationState.email}
                       onChange={onChangeEmail} disabled={customer && customer.email}/>
                <FormHelperText id="email-helper-text" error={errorEmail}>{errorEmail}</FormHelperText>
            </FormControl>
            <br/><br/>
            <FormControl fullWidth required={isRequired && inputsRequired.includes('phone')} error={errorPhone}>
                <InputLabel htmlFor="phone">Celular com DDD</InputLabel>
                <Input id="phone" aria-describedby="phone-helper-text" inputComponent={PhoneInput}
                       onChange={onChangePhone} value={identificationState.phone}
                       disabled={customer && customer.phoneNumber}/>
                <FormHelperText id="phone-helper-text" error={errorPhone}>
                    {`${errorPhone ? errorPhone : 'Ex.: (99) 9 9999 9999'}`}
                </FormHelperText>
            </FormControl>
            <br/><br/>
            <FormControl fullWidth required={isRequired && inputsRequired.includes('cep')} error={errorCEP}>
                <InputLabel htmlFor="cep">CEP</InputLabel>
                <Input id="cep" aria-describedby="cep-helper-text" value={identificationState.cep}
                       onChange={onChangeCep} disabled={customer && customer.address && customer.address.zipcode}
                       endAdornment={
                           <InputAdornment position={'end'}>
                               <a className={classes.a} href="https://achacep.com.br/" target="_blank"
                                  rel="noopener noreferrer">Não sei meu CEP</a>
                           </InputAdornment>
                       } inputComponent={CepInput}/>
                <FormHelperText error={errorCEP} id="cep-helper-text">{errorCEP}</FormHelperText>
            </FormControl>
            <br/><br/>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <FormControl fullWidth required={isRequired && inputsRequired.includes('cep')}>
                        <InputLabel htmlFor="address">Endereço</InputLabel>
                        <Input id="address" value={addressState.address} onChange={onChangeAddress}
                               disabled={loading || (customer && customer.address && customer.address.address)}
                               endAdornment={
                                   <InputAdornment position={'end'}>
                                       {
                                           loading && <CircularProgress size={sizeCircularProgress}/>
                                       }
                                   </InputAdornment>
                               }/>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth required={isRequired && inputsRequired.includes('cep')}>
                        <InputLabel htmlFor="address-number">Número</InputLabel>
                        <Input id="address-number" value={addressState.number} onChange={onChangeNumber}
                               aria-describedby={'address-number-helper'}
                               disabled={customer && customer.address && customer.address.number}/>
                        <FormHelperText id="address-number-helper">Número ou S/N</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="address-complement">Complemento</InputLabel>
                        <Input id="address-complement" value={addressState.complement} onChange={onChangeComplement}/>
                    </FormControl>
                    <FormHelperText id="complement-helper-text">Ex.: Apto 999</FormHelperText>
                </Grid>
                <Grid item xs={8}>
                    <FormControl fullWidth required={isRequired && inputsRequired.includes('cep')}>
                        <InputLabel htmlFor="address-neighborhood">Bairro</InputLabel>
                        <Input id="address-neighborhood" value={addressState.neighborhood}
                               disabled={loading || (customer && customer.address && customer.address.district)}
                               onChange={onChangeNeighborhood}
                               endAdornment={
                                   <InputAdornment position={'end'}>
                                       {
                                           loading && <CircularProgress size={sizeCircularProgress}/>
                                       }
                                   </InputAdornment>
                               }/>
                    </FormControl>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl fullWidth required={isRequired && inputsRequired.includes('cep')}>
                        <InputLabel htmlFor="address-city">Cidade</InputLabel>
                        <Input id="address-city" value={addressState.city} onChange={onChangeCity}
                               disabled={loading || (customer && customer.address && customer.address.city)}
                               endAdornment={
                                   <InputAdornment position={'end'}>
                                       {
                                           loading && <CircularProgress size={sizeCircularProgress}/>
                                       }
                                   </InputAdornment>
                               }/>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth required={isRequired && inputsRequired.includes('cep')}
                                 error={hasErrorState}>
                        <InputLabel htmlFor="address-state-select">Estado</InputLabel>
                        <Select value={addressState.state} onChange={onChangeState}
                                labelId="address-state-select"
                                disabled={loading || (customer && customer.address && customer.address.state)}
                                id="address-state-select" endAdornment={
                            <InputAdornment position={'end'}>
                                {
                                    loading && <CircularProgress size={sizeCircularProgress}/>
                                }
                            </InputAdornment>
                        }>
                            {
                                statesBrazil.map(stateBr => {
                                    return (<MenuItem key={stateBr} value={stateBr}> {stateBr} </MenuItem>)
                                })
                            }
                        </Select>
                        {hasErrorState && <FormHelperText>Campo obrigatório</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
            <br/><br/>
            <Button
                disabled={
                    loadingPayment ||
                    errorCEP ||
                    errorPhone ||
                    errorEmail ||
                    errorDocument ||
                    errorName ||
                    (isRequired && inputsRequired.includes('cep') && hasErrorState)
                }
                type="submit"
                color={'primary'}
                variant={'contained'}>
                {
                    paymentMethodSelected === 'card' &&
                    'Continuar'
                }
                {
                    paymentMethodSelected === 'bankSlip' &&
                    'Obter boleto'
                }
                {
                    paymentMethodSelected === 'bankTransfer' &&
                    'Transferir'
                }
                {
                    loadingPayment && <Loading size={sizeCircularProgress}/>
                }
            </Button>
        </form>
    )
}

export default withTheme(IdentificationForm)
