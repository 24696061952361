import './qrcodePix.scss';
import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {IconButton} from '@material-ui/core';
import InfoPix from '../info-pix/infoPix';
import QRCode from 'qrcode.react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import Tooltip from '@material-ui/core/Tooltip';
import {useStylesBootstrap} from '../../utils/useStylesBootstrap';

function QrcodePix({brcode}) {

    const [showPix, setShowPix] = React.useState(false)
    const [isCopied, setCopied] = React.useState(false)

    const classes = useStylesBootstrap();

    function copyToClipboard() {
        const textArea = document.createElement('textArea')
        textArea.innerText = brcode
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        textArea.remove()
        setCopied(true)
    }

    function getLegend() {
        if (isCopied) {
            return <legend className='copied'><CheckCircleIcon/> Chave Copiada</legend>
        }

        return <legend>Pix Copia e Cola</legend>
    }

    return (
        <div className='qrcode-pix'>
            <InfoPix onClose={() => setShowPix(false)} onConfirm={() => setShowPix(false)} visible={showPix}/>
            <div className='title'>
                <h2>QR Code Pix</h2>
                <Tooltip title="Clique e saiba mais" arrow classes={classes}>
                    <IconButton onClick={() => setShowPix(true)}>
                        <InfoOutlinedIcon color={'secondary'}/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className='content'>
                <div className='svg-qrcode'>
                    <QRCode value={brcode} renderAs={'svg'}/>
                </div>
                <br/>
                <fieldset className={`${isCopied ? 'copied' : ''} fieldset-copy`}>
                    {
                        getLegend()
                    }
                    <Tooltip arrow title="Basta copiar e colar" classes={classes}>
                        <div className='copy-clipboard'>
                            <p className='pix-key'>{brcode}</p>
                            <IconButton color="secondary" onClick={copyToClipboard} className='bt-copy'>
                                <InsertLinkIcon/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </fieldset>
            </div>
        </div>
    )
}

export default QrcodePix
