const apiBrandsByPass = {
    "banescard": "BANESCARD",
    "hipercard": "HIPERCARD",
    "hiper": "HIPER",
    "jcb": "JCB",
    "discover": "DISCOVER",
    "american-express": "AMEX",
    "elo": "ELO",
    "diners-club": "DINERS",
    "mastercard": "MASTERCARD",
    "sorocred": "SOROCRED",
    "cred-system": "CREDSYSTEM_MAIS",
    "credz": "CREDZ",
    "elo-caixa": "ELO_CAIXA",
    "cabal": "CABAL",
    "visa": "VISA",
    "aura": "AURA",
}

const brandsProps = {
    VISA: {
        config: {
            niceType: "Visa",
            type: "visa",
            patterns: [4],
            gaps: [4, 8, 12],
            lengths: [16, 18, 19],
            code: {
                name: "CVV",
                size: 3
            }
        }
    },
    MASTERCARD: {
        config: {
            niceType: "Mastercard",
            type: "mastercard",
            patterns: [
                [51, 55],
                [2221, 2229],
                [223, 229],
                [23, 26],
                [270, 271],
                2720
            ],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: "CVC",
                size: 3
            }
        }
    },
    DINERS: {
        config: {
            niceType: "Diners Club",
            type: "diners-club",
            patterns: [
                [300, 305],
                36,
                38,
                39
            ],
            gaps: [4, 10],
            lengths: [14, 16, 19],
            code: {
                name: "CVV",
                size: 3
            }
        }
    },
    ELO: {
        config: {
            niceType: "Elo",
            type: "elo",
            patterns: [
                401178,
                401179,
                438935,
                457631,
                457632,
                431274,
                451416,
                457393,
                504175,
                [506699, 506778],
                [509000, 509999],
                627780,
                636297,
                636368,
                [650031, 650033],
                [650035, 650051],
                [650405, 650439],
                [650485, 650538],
                [650541, 650598],
                [650700, 650718],
                [650720, 650727],
                [650901, 650978],
                [651652, 651679],
                [655000, 655019],
                [655021, 655058],
                [5067228, 5067229],
                [5091050, 5091055]
            ],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: "CVE",
                size: 3
            }
        }
    },
    ELO_CAIXA: {
        config: {
            niceType: "Elo Caixa",
            type: "elo-caixa",
            patterns: [
                509023,
                [5067228, 5067229],
                // [5091050, 5091055]
            ],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: "CVE",
                size: 3
            }
        }
    },
    AMEX: {
        config: {
            niceType: "American Express",
            type: "american-express",
            patterns: [34, 37],
            gaps: [4, 10],
            lengths: [15],
            code: {
                name: "CID",
                size: 4
            }
        }
    },
    DISCOVER: {
        config: {
            niceType: "Discover",
            type: "discover",
            patterns: [
                6011,
                [644, 649],
                65
            ],
            gaps: [4, 8, 12],
            lengths: [16, 19],
            code: {
                name: "CID",
                size: 3
            }
        }
    },
    JCB: {
        config: {
            niceType: "JCB",
            type: "jcb",
            patterns: [
                2131,
                1800,
                [3528, 3589]
            ],
            gaps: [4, 8, 12],
            lengths: [16, 17, 18, 19],
            code: {
                name: "CVV",
                size: 3
            }
        }
    },
    HIPER: {
        config: {
            niceType: "Hiper",
            type: "hiper",
            patterns: [
                637095,
                637568,
                637599,
                637609,
                637612
            ],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: "CVC",
                size: 3
            }
        }
    },
    HIPERCARD: {
        config: {
            niceType: "Hipercard",
            type: "hipercard",
            patterns: [606282],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: "CVC",
                size: 3
            }
        }
    },
    BANESCARD: {
        config: {
            niceType: 'Banescard',
            type: 'banescard',
            patterns: [6031],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: 'CVV',
                size: 3
            }
        }
    },
    CREDZ: {
        config: {
            niceType: 'Credz',
            type: 'credz',
            patterns: [6367],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: 'CVV',
                size: 3
            }
        }
    },
    CREDSYSTEM_MAIS: {
        config: {
            niceType: 'Credsystem',
            type: 'cred-system',
            patterns: [6280],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: 'CVV',
                size: 3
            }
        }
    },
    SOROCRED: {
        config: {
            niceType: 'Sorocred',
            type: 'sorocred',
            patterns: [6364],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: 'CVV',
                size: 3
            }
        }
    },
    CABAL: {
        config: {
            niceType: 'Cabal',
            type: 'cabal',
            patterns: [6042],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: 'CVV',
                size: 3
            }
        }
    },
    AURA: {
        config: {
            niceType: 'Aura',
            type: 'aura',
            patterns: [50],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: 'CVV',
                size: 3
            }
        }
    }
}

const brandsAvailable = [
    { name: "BANESCARD" },
    { name: "HIPERCARD" },
    { name: "HIPER" },
    { name: "JCB" },
    { name: "DISCOVER" },
    { name: "AMEX" },
    { name: "ELO" },
    { name: "DINERS" },
    { name: "MASTERCARD" },
    { name: "SOROCRED" },
    { name: "CREDSYSTEM_MAIS" },
    { name: "CREDZ" },
    { name: "ELO_CAIXA" },
    { name: "CABAL" },
    { name: "VISA" },
    { name: "AURA" }
]

export const brandsUtils = {
    apiBrandsByPass,
    brandsProps,
    brandsAvailable
}
