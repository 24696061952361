import React from 'react';
import {useStyles} from "./termsStyle";
import {withTheme} from "@material-ui/styles";
import {themes} from "../../themes/themes";

const themeSelected = process.env.REACT_APP_THEME

function Terms(props) {
    const theme = props.theme
    const classes = useStyles(theme)()

    return (
        <div className={classes.root}>
            <h4 className={classes.h4}>Processado por <img align="center" className={classes.img} src={themes[themeSelected].theme.processLogo}
                                                           alt={"logo"}/></h4>
            <p className={classes.p}>
                <strong>Importante!</strong> A PayGo não se responsabiliza
                <br/> (i) por preço e condições comerciais, características,
                quantidade, qualidade, entrega/fornecimento do bem ou serviço adquirido;
                <br/> (ii) eventual não aprovação,
                recusa ou cancelamento do pagamento;
                <br/> (iii) por problemas de conectividade do usuário com provedores de
                internet, telecomunicações e afins que impossibilitem, temporariamente ou não, o pagamento.
                <br/><br/>
                O desacordo comercial ou reclamações sobre o bem ou serviço adquirido ou ainda a contestação ou
                cancelamento do pagamento deverá ser resolvido(a) diretamente com o estabelecimento comercial (loja) ou
                com o banco emissor do seu cartão.
            </p>
        </div>
    )
}

export default withTheme(Terms)
