const TOKEN_CONST = 'token'

const saveToken = token => {
    localStorage.setItem(TOKEN_CONST, token)
}

const getToken = () => {
    return localStorage.getItem(TOKEN_CONST)
}

const clearSession = () => {
    localStorage.clear()
}

export const storage = {
    saveToken,
    getToken,
    clearSession
}
