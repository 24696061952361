import './tokenization.style.scss'
import React from 'react'
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    withTheme,
    Tooltip,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {cnpj, cpf} from 'cpf-cnpj-validator';
import CardIcon from '../../cardIcon/cardIcon';
import DocumentInput from '../../inputs/documentInput';
import creditCardType, {getTypeInfo, types} from 'credit-card-type'
import CardNumberInput from '../../inputs/cardNumberInput';
import CardValidateInput from '../../inputs/cardValidateInput';
import {containError} from '../../../utils/input.validation.util';
import {brandsUtils} from '../../../utils/brands-api.util';
import Loading from '../../loading/loading';

const cardStateInitial = {
    brandIsSelected: false,
    number: '',
    name: '',
    expiry: '',
    cvv: '',
    document: '',
    possibleCard: getTypeInfo(types.VISA)
}

let showInputAdornment = false;

function TokenizationForm({submit, ...props}) {

    const loading = props.loading
    const brands = props.brands
    const [card, setCard] = React.useState(cardStateInitial)
    let inputNumberRef = null

    React.useEffect(() => {
        brands.forEach(brand => {
            creditCardType.addCard(brandsUtils.brandsProps[brandsUtils.apiBrandsByPass[brand]].config)
        })
        setCard(prevState => {
            if (brands.includes(prevState.possibleCard.type)) {
                return {...prevState}
            }

            const brand = brands[0] ? brands[0] : 'visa'
            return {...prevState, possibleCard: getTypeInfo(brand)}
        })
    }, [brands])

    const possibleCard = card.possibleCard

    const inputsPropsValidation = {
        document: {
            props: {
                min: 14, max: 18, customs: [
                    value => {
                        const valueDocument = value.replace(/(\d*)([.\-/])/g, `$1`)
                        if (valueDocument.length === 11) {
                            if (!cpf.isValid(value)) {
                                return 'cpfNotValid'
                            }
                        }

                        if (valueDocument.length === 14) {
                            if (!cnpj.isValid(value)) {
                                return 'cnpjNotValid'
                            }
                        }
                    }
                ]
            },
            errors: {
                min: 'CPF/CNPJ deve conter no mínimo 14 caracteres!',
                max: 'CPF/CNPJ deve conter no máximo 18 caracteres!',
                cpfNotValid: 'CPF inválido',
                cnpjNotValid: 'CNPJ inválido',
            }
        },
        number: {
            props: {min: possibleCard ? possibleCard.lengths[0] : 0},
            errors: {min: `Cartão deve conter no mínimo ${possibleCard ? possibleCard.lengths[0] : 0} caracteres!`}
        },
        cvv: {
            props: {min: possibleCard ? possibleCard.code.size : 0, max: possibleCard ? possibleCard.code.size : 0},
            errors: {
                min: `${possibleCard ? possibleCard.code.name : 'CVV'} deve conter no mínimo ${possibleCard ? possibleCard.code.size : 0} caracteres!`,
                max: `${possibleCard ? possibleCard.code.name : 'CVV'} deve conter no máximo ${possibleCard ? possibleCard.code.size : 0} caracteres!`
            }
        },
        name: {
            props: {min: 3, max: 30},
            errors: {
                min: `Nome impresso no cartão deve conter no mínimo 3 caracteres!`,
                max: `Nome impresso no cartão deve conter no máximo 30 caracteres!`
            }
        },
        expiry: {
            props: {
                min: 5,
                customs: [
                    value => {
                        if (value.length === 5) {
                            const dates = value.split('/')
                            const month = parseInt(dates[0])

                            if (month < 1 || month > 12) {
                                return 'expiryMonthDate'
                            }

                            const today = new Date()
                            const year = parseInt(dates[1])
                            const yearActual = parseInt(today.getFullYear().toString().substr(2, 2))
                            if (year < yearActual) {
                                return 'expiryCard'
                            }

                            if (year === yearActual) {
                                const monthActual = today.getMonth() + 1
                                if (monthActual > month) {
                                    return 'expiryCard'
                                }
                            }
                        }

                        return false
                    }
                ]
            },
            errors: {
                min: 'Informe o mês e ano de validade do cartão. Ex.: MM/AA',
                expiryMonthDate: 'Por favor, insira um mês válido!',
                expiryCard: 'Cartão com validade expirada!'
            }
        }
    }

    const submitForm = e => {
        e.preventDefault()

        const cardData = {
            name: card.name,
            number: card.number,
            expiry: card.expiry,
            cvv: card.cvv,
            document: card.document,
            brand: card.possibleCard.type,
        }
        submit(cardData)
    }

    const onChangeCardNumber = e => {
        const result = e.target.value.replace(/([ ])/g, '')
        let possibleCard = card.possibleCard

        if (!card.brandIsSelected) {
            const brands = creditCardType(result)
            if (brands.length) {
                possibleCard = brands[0]
            }
        }

        showInputAdornment = result.length > 0;

        setCard({...card, possibleCard, number: result})
    }

    const onChangeValidate = e => {
        setCard({...card, expiry: e.target.value})
    }

    const onChangeCVV = e => {
        setCard({...card, cvv: e.target.value.replace(/([0-9]*)([a-zA-Z.+$,\][}#@!_=-Ç-()%/:;*ç'"])/g, `$1`)})
    }

    const onChangeName = e => {
        const result = e.target.value.replace(/([a-zA-Z]*)([0-9.+$,\][}#@!_=-Ç-()%/:;*'"]*)/g, `$1`).toUpperCase()
        setCard({...card, name: result})
    }

    const onChangeDocument = e => {
        setCard({...card, document: e})
    }

    const isIntentionWithValidateCard = () => {
        return typeof props.validateCard != 'undefined' && props.validateCard
    }

    const getWidthFromCardNumberField = () => {
        return isIntentionWithValidateCard() ? 12 : 8
    }

    const errorDocument = inputsPropsValidation.document.errors[containError(card.document, inputsPropsValidation.document.props)]
    const errorExpiry = inputsPropsValidation.expiry.errors[containError(card.expiry, inputsPropsValidation.expiry.props)]
    const errorNumber = inputsPropsValidation.number.errors[containError(card.number, inputsPropsValidation.number.props)]
    const errorCVV = inputsPropsValidation.cvv.errors[containError(card.cvv, inputsPropsValidation.cvv.props)]
    const errorName = inputsPropsValidation.name.errors[containError(card.name, inputsPropsValidation.name.props)]

    return (
        <form onSubmit={submitForm}>
            <Grid container spacing={3}>
                <Grid item xs={getWidthFromCardNumberField()}>
                    <FormControl fullWidth required error={errorNumber}>
                        <InputLabel htmlFor="number-card">Número do cartão</InputLabel>
                        <Input id="number-card" autoComplete="cc-number"
                            inputRef={ref => inputNumberRef = ref}
                            autoFocus={true}
                            gaps={card.possibleCard.gaps}
                            lengths={card.possibleCard.lengths}
                            aria-describedby="number-helper-text"
                            onChange={onChangeCardNumber}
                            endAdornment={
                                showInputAdornment && <InputAdornment position={'end'} className="preview-card-icon" >
                                        <CardIcon icon={ card.possibleCard.type } />
                                </InputAdornment>
                            }
                            inputComponent={CardNumberInput}
                            inputProps={{gaps: card.possibleCard.gaps, lengths: card.possibleCard.lengths}}/>
                        <FormHelperText id="number-helper-text" error={errorNumber}>{errorNumber}</FormHelperText>
                    </FormControl>
                </Grid>
                {
                    !isIntentionWithValidateCard() &&
                    <Grid item xs={4}>
                        <FormControl fullWidth required error={errorExpiry}>
                            <InputLabel htmlFor="validate-card">Validade</InputLabel>
                            <Input id="validate-card" autoComplete="cc-exp" inputComponent={CardValidateInput}
                                value={card.expiry} onChange={onChangeValidate}
                                aria-describedby={'expiry-helper-text'}/>
                            <FormHelperText id="expiry-helper-text" error={errorExpiry}>
                                {errorExpiry ? errorExpiry : ''}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                }
            </Grid>
            <br/><br/>
            {
                isIntentionWithValidateCard() &&
                <><Grid container spacing={3}>
                    <Grid item xs={8}>
                        <FormControl fullWidth required error={errorExpiry}>
                            <InputLabel htmlFor="validate-card">Validade</InputLabel>
                            <Input id="validate-card" autoComplete="cc-exp" inputComponent={CardValidateInput}
                                value={card.expiry} onChange={onChangeValidate}
                                aria-describedby={'expiry-helper-text'} />
                            <FormHelperText id="expiry-helper-text" error={errorExpiry}>
                                {errorExpiry ? errorExpiry : ''}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth required error={errorCVV}>
                            <InputLabel htmlFor="cvv-card">{card.possibleCard.code.name}</InputLabel>
                            <Input id="cvv-card" autoComplete="cc-csc"
                                value={card.cvv}
                                onChange={onChangeCVV}
                                aria-describedby={'cvv-helper-text'}
                                endAdornment={<InputAdornment position={'end'}>
                                    <Tooltip
                                        title="Os três ou quatro números que geralmente ficam no verso do seu cartão."
                                        placement="top">
                                        <InfoOutlinedIcon color={'primary'} />
                                    </Tooltip>
                                </InputAdornment>} />
                            <FormHelperText id={'cvv-helper-text'} error={errorCVV}>{errorCVV}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <br /><br /></>
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth required error={errorName}>
                        <InputLabel htmlFor="name-card">Nome impresso no cartão</InputLabel>
                        <Input id="name-card" autoComplete="cc-name" value={card.name} onChange={onChangeName}/>
                        <FormHelperText id="name-helper-text" error={errorName}></FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth error={errorDocument}>
                        <InputLabel htmlFor="document-card">CPF/CNPJ</InputLabel>
                        <Input id="document-card" inputComponent={DocumentInput} value={card.document}
                               onChange={onChangeDocument} aria-describedby={'document-helper-text'}/>
                        <FormHelperText id="document-helper-text" error={errorDocument}>{errorDocument}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={2}>
                {
                    props.cancelPayment != null &&
                    <Grid item xs={'auto'}>
                        <Button disabled={loading} type="button" variant={'contained'}
                                onClick={props.cancelPayment}>Voltar</Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <div align="center" style={{display: 'flex', alignItems: 'left', justifyContent: 'left'}} t={8}>
                        <Button
                            disabled={loading || errorName || errorNumber || errorExpiry || errorDocument}
                            className="button"
                            type="submit"
                            variant={'contained'}>
                            Salvar
                        </Button>
                        {
                            loading && <Loading size="1.3rem"/>
                        }
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

export default withTheme(TokenizationForm)
