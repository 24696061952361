function getVisitorID(callbackVisitorID) {
    const period = 300;
    const limit = 20 * 1e3;
    let nTry = 0;
    const intervalID = setInterval(() => {
        console.log('verifing if Konduto is available')
        let clear = limit / period <= ++nTry;
        if (typeof (window.Konduto) !== 'undefined' && typeof (window.Konduto.getVisitorID) !== 'undefined') {
            callbackVisitorID(window.Konduto.getVisitorID())
            clear = true;
        }

        if (clear) {
            clearInterval(intervalID);
        }
    }, period)
}

function loadKondutoLibAsync(publicKey, onScriptLoad) {
    window.__kdt = [];
    window.__kdt.push({'public_key': publicKey});
    window.__kdt.push({'post_on_load': true});
    const kdt = document.createElement('script');
    kdt.id = 'kdtjs';
    kdt.type = 'text/javascript';
    kdt.async = true;
    kdt.src = 'https://i.k-analytix.com/k.js';
    kdt.onload = () => onScriptLoad();
    document.body.appendChild(kdt);
}

export const kondutoUtil = {
    getVisitorID,
    loadKondutoLibAsync
}
